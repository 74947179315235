import React from 'react';
import { Directions, VEHICLE_TYPE } from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IPersonOnObjectProps,
  IDefaultResponsiveSupport,
  IPersonProps,
  Layout,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import { ISetPersonInCarProps } from '../../../context/action';
import CarComponent from '../above-platform/car/car.component';
import styles from './person-in-car.module.scss';

interface IPersonInCarComponent extends IDefaultResponsiveSupport {
  layout: Layout;
  personProps: IPersonProps;
  personInCarProps: IPersonOnObjectProps;
  setPersonInCarProps: ISetPersonInCarProps;
}
export default class PersonInCarComponent extends React.Component<IPersonInCarComponent> {
  private fixPosition({
    layout: { width: layoutWidth },
    personInCarProps: { ref },
    setPersonInCarProps,
  }: IPersonInCarComponent) {
    if (ref && ref.current) {
      ref.current.style.left = `${layoutWidth / 2 - ref.current.offsetWidth / 2}px`;
      const { left, right, width, height } =
        ref?.current?.getBoundingClientRect();
        setPersonInCarProps({
        getBoundingClientRect: {
          left,
          right,
          width,
          height,
        },
      });
    }
  }

  shouldComponentUpdate(nextProps: IPersonInCarComponent) {
    const { responsiveMode, personProps, layout } = this.props;
    if (
      layout.width !== nextProps.layout.width ||
      layout.height !== nextProps.layout.height
    ) {
      this.fixPosition(nextProps);
      return false;
    }

    if (
      personProps.direction !== nextProps.personProps.direction ||
      personProps.isRunning !== nextProps.personProps.isRunning ||
      responsiveMode !== nextProps.responsiveMode ||
      personProps.isVisible !== nextProps.personProps.isVisible
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.fixPosition(this.props);
  }

  render() {
    const { responsiveMode, personProps, personInCarProps } = this.props;

    return (
      <div
        ref={personInCarProps.ref}
        className={classnames(
          styles.personOnBoatContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          {
            [styles.hidden]: !personInCarProps.isVisible,
          }
        )}
      >
        <CarComponent
          responsiveMode={responsiveMode}
          isRotateBack={personProps.direction === Directions.ArrowLeft}
          isVisible={true}
          isRunning={personProps.isRunning}
          type={VEHICLE_TYPE.FULL}
        />
      </div>
    );
  }
}
