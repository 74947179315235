import React from 'react';
import {
  ABOUT_ME_LEFT,
  ABOVE_PLATFORM_BOATS,
  ABOVE_PLATFORM_CARS,
  ABOVE_PLATFORM_GATES,
  ABOVE_PLATFORM_TRAINS,
  BLOCKS_NAME,
  BLOCKS_PROPERTIES,
  EMPLOYEMENTHISTORY_LEFT,
  FIRECRACKERS_LEFT,
  GRADUATION_LEFT,
  HIGHER_EDUCATION_LEFT,
  PROJECTS_LEFT,
  SKILLS_LEFT,
  VEHICLE_TYPE,
} from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IAboutMe,
  IDefaultResponsiveSupport,
  IPersonOnObjectProps,
  IPersonProps,
  IResumeData,
  Layout,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import {
  ISetPersonInCarProps,
  ISetPersonOnBoatProps,
  ISetPersonProps,
  ISetShouldMoveScrolls,
  ISetSkyProps,
} from '../../../context/action';
import BlockComponent from './block/block.component';
import styles from './above-platform.module.scss';
import GateComponent from './gate/gate.component';
import BoatComponent from './boat/boat.component';
import LighthoseComponent from './lighthouse/lighthouse.component';
import TrainComponent from './train/train.component';
import CarComponent from './car/car.component';
import EmploymentHistory from './employment-history/employment-history.component';
import AboutMeComponent from './about-me/about-me.component';
import HigherEducation from './higher-education/higher-education.component';
import GraduationComponent from './graduation/graduation.component';
import ProjectsComponent from './projects/projects.component';
import SkillsComponent from './skills/skills.component';
import Firecrackers from './firecrackers/firecrackers.component';

interface IAbovePlatformComponent extends IDefaultResponsiveSupport {
  scrollPostion: number;
  shouldMoveScrolls: boolean;
  personProps: IPersonProps;
  setShouldMoveScrolls: ISetShouldMoveScrolls;
  setPersonProps: ISetPersonProps;
  resumeData: IResumeData;
  layout: Layout;
  personOnBoatProps: IPersonOnObjectProps;
  personOnTrainProps: IPersonOnObjectProps;
  personInCarProps: IPersonOnObjectProps;
  setPersonOnBoatProps: ISetPersonOnBoatProps;
  setPersonOnTrainProps: ISetPersonOnBoatProps;
  setPersonInCarProps: ISetPersonInCarProps;
  setSkyProps: ISetSkyProps;
  aboutMe: IAboutMe;
}
export default class AbovePlatformComponent extends React.PureComponent<IAbovePlatformComponent> {
  private eleRef: React.RefObject<any>;

  constructor(props: IAbovePlatformComponent) {
    super(props);
    this.eleRef = React.createRef();
  }

  componentDidUpdate(prevProps: IAbovePlatformComponent) {
    const {
      scrollPostion,
      personProps: { direction },
      personOnBoatProps,
      personOnTrainProps,
      personInCarProps,
    } = this.props;

    if (scrollPostion !== prevProps.scrollPostion) {
      this.eleRef.current.scrollTo(scrollPostion, 0);
    }

    if (
      scrollPostion !== prevProps.scrollPostion ||
      direction !== prevProps.personProps.direction
    ) {
      this.setBoatVisibilities();
      this.setTrainVisibilities();
      this.setCarVisibilities();
    }

    if (
      prevProps.personOnBoatProps.getBoundingClientRect.left !==
        personOnBoatProps.getBoundingClientRect.left ||
      prevProps.personOnBoatProps.getBoundingClientRect.right !==
        personOnBoatProps.getBoundingClientRect.right ||
      prevProps.personOnBoatProps.getBoundingClientRect.width !==
        personOnBoatProps.getBoundingClientRect.width
    ) {
      this.setBoatVisibilities();
    }

    if (
      prevProps.personOnTrainProps.getBoundingClientRect.left !==
        personOnTrainProps.getBoundingClientRect.left ||
      prevProps.personOnTrainProps.getBoundingClientRect.right !==
        personOnTrainProps.getBoundingClientRect.right ||
      prevProps.personOnTrainProps.getBoundingClientRect.width !==
        personOnTrainProps.getBoundingClientRect.width
    ) {
      this.setTrainVisibilities();
    }

    if (
      prevProps.personInCarProps.getBoundingClientRect.left !==
        personInCarProps.getBoundingClientRect.left ||
      prevProps.personInCarProps.getBoundingClientRect.right !==
        personInCarProps.getBoundingClientRect.right ||
      prevProps.personInCarProps.getBoundingClientRect.width !==
        personInCarProps.getBoundingClientRect.width
    ) {
      this.setCarVisibilities();
    }
  }

  render() {
    const { responsiveMode } = this.props;
    return (
      <div
        ref={this.eleRef}
        className={classnames(
          'fullScreenWindow',
          styles.abovePlatformContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div className={classnames('windowReel', styles.backgroundImages)}>
          {this.firecrackers}
          {this.blocks}
          {this.gates}
          {this.aboutMe}
          {this.higherEducation}
          {this.boats}
          {this.lighthouse}
          {this.trains}
          {this.graduation}
          {this.cars}
          {this.employmentHistory}
          {this.skills}
          {this.projects}
        </div>
      </div>
    );
  }

  private get blocks() {
    const {
      responsiveMode,
      personProps,
      scrollPostion,
      shouldMoveScrolls,
      setShouldMoveScrolls,
      setPersonProps,
    } = this.props;
    return BLOCKS_PROPERTIES.map((block) => (
      <BlockComponent
        key={block.key}
        id={block.key}
        classes={block.classes}
        personProps={personProps}
        setShouldMoveScrolls={setShouldMoveScrolls}
        setPersonProps={setPersonProps}
        scrollPostion={scrollPostion}
        left={block.left}
        responsiveMode={responsiveMode}
        type={block.type}
        name={block.name}
        shouldMoveScrolls={shouldMoveScrolls}
      />
    ));
  }

  private get gates() {
    const { responsiveMode } = this.props;
    return ABOVE_PLATFORM_GATES.map((gateProps: any) => {
      return (
        <GateComponent
          key={gateProps.key}
          title={gateProps.title}
          subTitle={gateProps.subTitle}
          lightColor={gateProps.lightColor}
          darkColor={gateProps.darkColor}
          left={gateProps.left}
          responsiveMode={responsiveMode}
        />
      );
    });
  }

  private get boats() {
    const style: React.CSSProperties = {
      position: 'absolute',
      bottom: 0,
    };
    const { personOnBoatProps, responsiveMode } = this.props;
    return ABOVE_PLATFORM_BOATS.map((boat, i) => {
      return (
        <BoatComponent
          key={i}
          style={{
            ...style,
            left: boat.left,
          }}
          responsiveMode={responsiveMode}
          forwardRef={boat.forwardRef}
          isRotateBack={boat.isRotateBack}
          isVisible={!personOnBoatProps.isVisible}
          type={VEHICLE_TYPE.EMPTY}
          position={boat.position}
        />
      );
    });
  }

  private setBoatVisibilities() {
    const {
      personProps: { currentPlatform },
      personOnBoatProps: {
        isVisible: isPresonOnBoatVisible,
        getBoundingClientRect: getPersonOnBoatBoundingClientRect,
      },
      setPersonProps,
      setPersonOnBoatProps,
    } = this.props;
    const currentBlockName =
      currentPlatform?.current?.getAttribute('data-name');
    if (currentBlockName === BLOCKS_NAME.SEA_BLOCK) {
      const emptyLeftBoat = (
        ABOVE_PLATFORM_BOATS[0].forwardRef as React.RefObject<any>
      )?.current?.getBoundingClientRect();
      const emptyRightBoat = (
        ABOVE_PLATFORM_BOATS[1].forwardRef as React.RefObject<any>
      )?.current?.getBoundingClientRect();
      if (
        getPersonOnBoatBoundingClientRect?.right > emptyLeftBoat.right &&
        getPersonOnBoatBoundingClientRect?.right < emptyRightBoat.right &&
        !isPresonOnBoatVisible
      ) {
        setPersonProps({ isVisible: false });
        setPersonOnBoatProps({ isVisible: true });
      } else if (
        isPresonOnBoatVisible &&
        (getPersonOnBoatBoundingClientRect.right < emptyLeftBoat.right ||
          getPersonOnBoatBoundingClientRect.right > emptyRightBoat.right)
      ) {
        setPersonProps({ isVisible: true });
        setPersonOnBoatProps({ isVisible: false });
        getPersonOnBoatBoundingClientRect.right < emptyLeftBoat.right &&
          (ABOVE_PLATFORM_BOATS[0].isRotateBack = true);
      }
    }
  }

  private get lighthouse() {
    const { responsiveMode } = this.props;
    return <LighthoseComponent left={4200} responsiveMode={responsiveMode} />;
  }

  private get trains() {
    const style: React.CSSProperties = {
      position: 'absolute',
      bottom: 0,
      backgroundPositionY: '-160px',
    };
    const { personOnTrainProps, responsiveMode } = this.props;
    return ABOVE_PLATFORM_TRAINS.map((train, i) => {
      return (
        <TrainComponent
          key={i}
          style={{
            ...style,
            left: train.left,
          }}
          responsiveMode={responsiveMode}
          forwardRef={train.forwardRef}
          isRotateBack={train.isRotateBack}
          isVisible={!personOnTrainProps.isVisible}
          type={VEHICLE_TYPE.EMPTY}
          position={train.position}
        />
      );
    });
  }

  private setTrainVisibilities() {
    const {
      personOnTrainProps: {
        isVisible: isPresonOnTrainVisible,
        getBoundingClientRect: getPersonOnTrainBoundingClientRect,
      },
      setPersonProps,
      setPersonOnTrainProps,
    } = this.props;

    const emptyLeftTrain = (
      ABOVE_PLATFORM_TRAINS[0].forwardRef as React.RefObject<any>
    )?.current?.getBoundingClientRect();
    const emptyRightTrain = (
      ABOVE_PLATFORM_TRAINS[1].forwardRef as React.RefObject<any>
    )?.current?.getBoundingClientRect();
    if (
      getPersonOnTrainBoundingClientRect?.right > emptyLeftTrain.right &&
      getPersonOnTrainBoundingClientRect?.right < emptyRightTrain.right &&
      !isPresonOnTrainVisible
    ) {
      setPersonProps({ isVisible: false });
      setPersonOnTrainProps({ isVisible: true });
    } else if (
      isPresonOnTrainVisible &&
      (getPersonOnTrainBoundingClientRect.right < emptyLeftTrain.right ||
        getPersonOnTrainBoundingClientRect.right > emptyRightTrain.right)
    ) {
      setPersonProps({ isVisible: true });
      setPersonOnTrainProps({ isVisible: false });
      getPersonOnTrainBoundingClientRect.right < emptyLeftTrain.right &&
        (ABOVE_PLATFORM_TRAINS[0].isRotateBack = true);
    }
  }

  private get cars() {
    const style: React.CSSProperties = {
      position: 'absolute',
      bottom: 0,
    };
    const { personInCarProps, responsiveMode } = this.props;
    return ABOVE_PLATFORM_CARS.map((car, i) => {
      return (
        <CarComponent
          key={i}
          style={{
            ...style,
            left: car.left,
          }}
          responsiveMode={responsiveMode}
          forwardRef={car.forwardRef}
          isRotateBack={car.isRotateBack}
          isVisible={!personInCarProps.isVisible}
          type={VEHICLE_TYPE.EMPTY}
          position={car.position}
        />
      );
    });
  }

  private setCarVisibilities() {
    const {
      personInCarProps: {
        isVisible: isPresonInCarVisible,
        getBoundingClientRect: getPersonInCarBoundingClientRect,
      },
      setPersonProps,
      setPersonInCarProps,
    } = this.props;

    const emptyLeftTrain = (
      ABOVE_PLATFORM_CARS[0].forwardRef as React.RefObject<any>
    )?.current?.getBoundingClientRect();
    const emptyRightTrain = (
      ABOVE_PLATFORM_CARS[1].forwardRef as React.RefObject<any>
    )?.current?.getBoundingClientRect();
    if (
      getPersonInCarBoundingClientRect?.right > emptyLeftTrain.right &&
      getPersonInCarBoundingClientRect?.right < emptyRightTrain.right &&
      !isPresonInCarVisible
    ) {
      setPersonProps({ isVisible: false });
      setPersonInCarProps({ isVisible: true });
    } else if (
      isPresonInCarVisible &&
      (getPersonInCarBoundingClientRect.right < emptyLeftTrain.right ||
        getPersonInCarBoundingClientRect.right > emptyRightTrain.right)
    ) {
      setPersonProps({ isVisible: true });
      setPersonInCarProps({ isVisible: false });
      getPersonInCarBoundingClientRect.right < emptyLeftTrain.right &&
        (ABOVE_PLATFORM_CARS[0].isRotateBack = true);
    }
  }

  private get employmentHistory() {
    const {
      personProps,
      responsiveMode,
      scrollPostion,
      resumeData,
      setSkyProps,
    } = this.props;
    return (
      <>
        <EmploymentHistory
          left={EMPLOYEMENTHISTORY_LEFT}
          personProps={personProps}
          responsiveMode={responsiveMode}
          scrollPostion={scrollPostion}
          employmentHistorys={JSON.parse(
            JSON.stringify(resumeData?.employmentHistory)
          )}
          setSkyProps={setSkyProps}
        />
      </>
    );
  }

  private get aboutMe() {
    const { responsiveMode, personProps, scrollPostion, aboutMe } = this.props;
    return (
      <AboutMeComponent
        left={ABOUT_ME_LEFT}
        responsiveMode={responsiveMode}
        personProps={personProps}
        scrollPostion={scrollPostion}
        aboutMe={aboutMe}
      />
    );
  }

  private get higherEducation() {
    const {
      responsiveMode,
      personProps,
      scrollPostion,
      resumeData,
      setSkyProps,
    } = this.props;
    return (
      <HigherEducation
        left={HIGHER_EDUCATION_LEFT}
        responsiveMode={responsiveMode}
        personProps={personProps}
        scrollPostion={scrollPostion}
        education={[resumeData.education[2], resumeData.education[1]]}
        setSkyProps={setSkyProps}
      />
    );
  }

  private get graduation() {
    const {
      responsiveMode,
      personProps,
      scrollPostion,
      resumeData,
      setSkyProps,
    } = this.props;
    return (
      <GraduationComponent
        left={GRADUATION_LEFT}
        responsiveMode={responsiveMode}
        personProps={personProps}
        scrollPostion={scrollPostion}
        education={resumeData.education[0]}
        setSkyProps={setSkyProps}
      />
    );
  }

  private get projects() {
    const { responsiveMode, resumeData } = this.props;
    return (
      <>
        <ProjectsComponent
          left={PROJECTS_LEFT}
          projects={JSON.parse(JSON.stringify(resumeData.projects))}
          responsiveMode={responsiveMode}
        />
      </>
    );
  }

  private get skills() {
    const { responsiveMode, resumeData, personProps, scrollPostion } =
      this.props;
    return (
      <>
        <SkillsComponent
          left={SKILLS_LEFT}
          professionalSkills={resumeData.professionalSkills}
          responsiveMode={responsiveMode}
          personProps={personProps}
          scrollPostion={scrollPostion}
        />
      </>
    );
  }
  private get firecrackers() {
    const { responsiveMode, personProps, scrollPostion } = this.props;
    return (
      <>
        <Firecrackers
          left={FIRECRACKERS_LEFT}
          responsiveMode={responsiveMode}
          personProps={personProps}
          scrollPostion={scrollPostion}
        />
      </>
    );
  }
}
