import React from 'react';
import { buildingType1Avatar } from '../../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IEducation,
  IPersonProps,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import { ISetSkyProps } from '../../../../context/action';
import { CLOUD_TYPES } from '../../cloud/cloud.component';
import BoardComponent from '../board/board.component';
import styles from './higher-education.module.scss';

interface IHigherEducation extends IDefaultResponsiveSupport {
  left: number;
  scrollPostion: number;
  personProps: IPersonProps;
  education: IEducation[];
  setSkyProps: ISetSkyProps;
}

interface IHigherEducationState {
  runAnimation: boolean;
}

export default class HigherEducation extends React.Component<
  IHigherEducation,
  IHigherEducationState
> {
  private eleRef: React.RefObject<any>;

  constructor(props: IHigherEducation) {
    super(props);
    this.eleRef = React.createRef();
    this.state = {
      runAnimation: false,
    };
  }

  shouldComponentUpdate(
    nextProps: IHigherEducation,
    nextState: IHigherEducationState
  ) {
    const { scrollPostion, responsiveMode } = this.props;
    if (scrollPostion !== nextProps.scrollPostion && !this.state.runAnimation) {
      this.runAnimation(nextProps);
    }

    if (
      responsiveMode !== nextProps.responsiveMode ||
      this.state.runAnimation !== nextState.runAnimation
    ) {
      return true;
    }
    return false;
  }

  private runAnimation({
    personProps: { getBoundingClientRect: getPersonBoundingClientRect },
    setSkyProps
  }: IHigherEducation) {
    const block = this.eleRef?.current?.getBoundingClientRect();
    if (getPersonBoundingClientRect?.right > block.left) {
      this.setState({ runAnimation: true });
      setSkyProps({ backgroundColor: styles.skyColor2, cloudType: CLOUD_TYPES.CLOUD_TYPE_2 });
    }
  }

  render() {
    const { responsiveMode, left, education } = this.props;

    return (
      <div
        ref={this.eleRef}
        style={{
          left,
        }}
        className={classnames(
          'flex-row',
          styles.higherEducationContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div
          style={{ backgroundImage: buildingType1Avatar }}
          className={classnames('size-auto', styles.buildingImg, {
            [styles.runBuildingAnim]: this.state.runAnimation,
          })}
        />
        <div
          style={{ animationDelay: '0.7s' }}
          className={classnames('size-auto', styles.class10, {
            [styles.runBoardAnim]: this.state.runAnimation,
          })}
        >
          <BoardComponent
            lightColor={styles.red10}
            darkColor={styles.red20}
            textColor={styles.gray0}
            justifyContent={'space-around'}
            responsiveMode={responsiveMode}
          >
            <h1
              dangerouslySetInnerHTML={{
                __html: `${education[0].institute}`,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: `${education[0].exam}, Year: ${education[0].year}`,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: `Marks: ${education[0].result}`,
              }}
            />
          </BoardComponent>
        </div>
        <div
          style={{ animationDelay: '1.5s' }}
          className={classnames('size-auto', styles.class12, {
            [styles.runBoardAnim]: this.state.runAnimation,
          })}
        >
          <BoardComponent
            lightColor={styles.red20}
            darkColor={styles.red10}
            textColor={styles.gray0}
            justifyContent={'space-around'}
            responsiveMode={responsiveMode}
          >
            <h1
              dangerouslySetInnerHTML={{
                __html: `${education[1].institute}`,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: `${education[1].exam}, Year: ${education[1].year}`,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: `Marks: ${education[1].result}`,
              }}
            />
          </BoardComponent>
        </div>
      </div>
    );
  }
}
