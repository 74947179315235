import React from 'react';
import { graduatedAvatar } from '../../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IEducation,
  IPersonProps,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import { ISetSkyProps } from '../../../../context/action';
import { CLOUD_TYPES } from '../../cloud/cloud.component';
import CollegeScoreCard from '../college-scorecard/college-score-card.component';
import styles from './graduation.module.scss';

interface IGraduationComponent extends IDefaultResponsiveSupport {
  left: number;
  scrollPostion: number;
  personProps: IPersonProps;
  education: IEducation;
  setSkyProps: ISetSkyProps;
}

interface IGraduationComponentState {
  runAnimationBuilding: boolean;
  runAnimationCard: boolean;
}

export default class GraduationComponent extends React.Component<
  IGraduationComponent,
  IGraduationComponentState
> {
  private eleRef: React.RefObject<any>;

  constructor(props: IGraduationComponent) {
    super(props);
    this.eleRef = React.createRef();
    this.state = {
      runAnimationBuilding: false,
      runAnimationCard: false,
    };
  }

  shouldComponentUpdate(
    nextProps: IGraduationComponent,
    nextState: IGraduationComponentState
  ) {
    const { scrollPostion, responsiveMode } = this.props;
    if (
      scrollPostion !== nextProps.scrollPostion &&
      (!this.state.runAnimationBuilding || !this.state.runAnimationCard)
    ) {
      this.runAnimation(nextProps);
    }

    if (
      responsiveMode !== nextProps.responsiveMode ||
      this.state.runAnimationBuilding !== nextState.runAnimationBuilding ||
      this.state.runAnimationCard !== nextState.runAnimationCard
    ) {
      return true;
    }
    return false;
  }

  private runAnimation({
    personProps: { getBoundingClientRect: getPersonBoundingClientRect },
    setSkyProps,
  }: IGraduationComponent) {
    const block = this.eleRef?.current?.getBoundingClientRect();
    if (
      getPersonBoundingClientRect?.right > block.left &&
      !this.state.runAnimationBuilding
    ) {
      this.setState({ runAnimationBuilding: true });
      setSkyProps({
        backgroundColor: styles.skyColor3,
        cloudType: CLOUD_TYPES.CLOUD_TYPE_3,
        isStarVisible: true,
      });
    }

    if (
      getPersonBoundingClientRect?.right > block.right &&
      !this.state.runAnimationCard
    ) {
      this.setState({ runAnimationCard: true });
    }
  }

  render() {
    const { responsiveMode, left, education } = this.props;

    return (
      <div
        ref={this.eleRef}
        style={{
          left,
        }}
        className={classnames(
          'flex-row',
          styles.graduationContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div
          style={{ backgroundImage: graduatedAvatar }}
          className={classnames('size-auto', styles.buildingImg, {
            [styles.runBuildingAnim]: this.state.runAnimationBuilding,
          })}
        />
        <CollegeScoreCard
          responsiveMode={responsiveMode}
          education={education}
          runAnimation={this.state.runAnimationCard}
        />
      </div>
    );
  }
}
