import React from 'react';
import { Directions } from '../../common/constant/interactive.resume.constant';
import {
  CancelSVG,
  CheckSVG,
} from '../../common/constant/resume-data.constant';
import { classnames } from '../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../common/interfaces/common.interface';
import AnimationService from '../../common/services/animatin.service';
import ResponsiveModeService from '../../common/services/responsive-mode.service';
import styles from './message.module.scss';

interface IMessageComponent extends IDefaultResponsiveSupport {
  msg: string;
  isOpen?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  showCancelBtn?: boolean;
  direction?: 'top' | 'bottom';
}

interface IMessageComponentState {
  isOpen: boolean;
}

export default class MessageComponent extends React.Component<
  IMessageComponent,
  IMessageComponentState
> {
  static defaultProps: Partial<IMessageComponent> = {
    showCancelBtn: true
  }
  constructor(props: IMessageComponent) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  shouldComponentUpdate(
    nextProps: IMessageComponent,
    nextState: IMessageComponentState
  ) {
    if (
      this.props.isOpen !== nextProps.isOpen ||
      this.state.isOpen !== nextState.isOpen
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isOpen: this.props.isOpen ? true : false });
      this.props.isOpen && AnimationService.keyUp(Directions.ArrowRight);
    }, 1000);
  }

  componentDidUpdate(prevProps: IMessageComponent) {
    if (this.props.isOpen !== prevProps.isOpen) {
      setTimeout(() => {
        this.setState({ isOpen: this.props.isOpen ? true : false });
        this.props.isOpen && AnimationService.keyUp(Directions.ArrowRight);
      }, 500);
    }
  }

  handleBtnClick = (type: 'ok' | 'cancel') => {
    const { onSuccess, onCancel } = this.props;
    switch (type) {
      case 'ok':
        onSuccess && onSuccess();
        break;
      case 'cancel':
        onCancel && onCancel();
        break;
    }
    this.setState({ isOpen: false });
  };

  render() {
    const { responsiveMode, msg, onSuccess, showCancelBtn, direction } = this.props;
    return (
      <div
        className={classnames(
          styles.messageContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          this.state.isOpen ? styles.open : styles.close,
          direction === 'bottom' ? styles.directionBottom : styles.directionTop
        )}
      >
        <div
          onClick={this.handleBtnClick.bind(this, 'cancel')}
          className={styles.blackBg}
        ></div>
        <div className={classnames(styles.childrenContainer)}>
          <div className={classnames('flex-row', styles.children)}>
            <p className={classnames('size-auto')}>{msg}</p>
              {onSuccess && (
                <CheckSVG
                  title={'Ok'}
                  onClick={this.handleBtnClick.bind(this, 'ok')}
                  className={classnames('size-auto', styles.SVGIcon)}
                />
              )}
              {
                showCancelBtn && <CancelSVG
                  title={'Cancel'}
                  onClick={this.handleBtnClick.bind(this, 'cancel')}
                  className={classnames('size-auto', styles.SVGIcon)}
                />
              }
          </div>
        </div>
      </div>
    );
  }
}
