import {
  ISetDarkMode,
  ISetLayoutSize,
  ISetResponsiveMode,
  ISetScreenProps,
} from '../../context/action';
import { SET_DARK_MODE } from '../../context/action-type-keys.constant';
import { pictures } from '../constant/interactive.resume.constant';
import {
  getResponsiveMode,
  getWindowSize,
  isFullScreen,
  mediaOrientationQueryList,
} from '../general-utilities/general-utilities';
import { SCREEN_ORIENTATION } from '../interfaces/common.interface';
import { name, version } from '../../../package.json';

let bootstrapped = false;
class LayoutService {
  public static bootstrap(
    setLayoutSize?: ISetLayoutSize,
    setResponsiveMode?: ISetResponsiveMode,
    setDarkMode?: ISetDarkMode,
    setScreenProps?: ISetScreenProps
  ) {
    if (bootstrapped) {
      return;
    }
    const worker = () => {
      if (setLayoutSize && setResponsiveMode) {
        const { width, height } = getWindowSize();
        setLayoutSize({ width, height });
        setResponsiveMode(getResponsiveMode(width));
      }
    };
    const onOrientationChange = (e: MediaQueryListEvent) => {
      setScreenProps &&
        setScreenProps({
          orientation: e.matches ? SCREEN_ORIENTATION.PORTRAIT : SCREEN_ORIENTATION.LANDSCAPE
        });
    };

    const onFullScreenChange = (e: Event) => {
      setScreenProps &&
        setScreenProps({ isFullScreen: isFullScreen() });
    };

    mediaOrientationQueryList.addEventListener('change', onOrientationChange);
    window.addEventListener('resize', worker);
    document.addEventListener('fullscreenchange', onFullScreenChange);
    worker();
    this.setDarkMode(setDarkMode);
    bootstrapped = true;
    (window as any).version = version;
    (window as any).name = name;
  }

  public static openfullscreen() {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions =
      document.documentElement as HTMLElement & {
        mozRequestFullScreen(): Promise<void>;
        webkitRequestFullscreen(): Promise<void>;
        msRequestFullscreen(): Promise<void>;
      };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
      /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
      /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    } else {
      alert("Full screen mode is not support on this device.");
    }
  }

  public static closefullscreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
      /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
      /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 0);
  }

  public static async preloadImages() {
    const promises = pictures.map((img) => {
      return new Promise((resolve, reject) => {
        const cacheImg = new Image();
        cacheImg.src = img;
        cacheImg.onload = () => resolve(true);
        cacheImg.onerror = () => reject();
      });
    });
    await Promise.all(promises);
    return true;
  }

  public static setDarkMode(setDarkMode?: ISetDarkMode) {
    const isDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    const isDarkStorageState = localStorage.getItem(SET_DARK_MODE);
    !isDarkStorageState && setDarkMode && setDarkMode(isDark);
  }

  public static rotateScreen(angle: SCREEN_ORIENTATION) {
    if(!window?.screen?.orientation) {
      alert("Rotation support is not available on this device.");
      return;
    }
    this.openfullscreen();
    setTimeout(() => {
      window.screen.orientation
        .lock(angle)
        .then(function (_e) {
        })
        .catch(function (_error) {
          alert("Rotation support is not available on this device.")
        });
    }, 0);
  }
}

export default LayoutService;
