import React from 'react';
import {
  classnames,
  generateIntRandomNumber,
} from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './cloud.module.scss';

export enum CLOUD_TYPES {
  CLOUD_TYPE_1 = 'CLOUD_TYPE_1',
  CLOUD_TYPE_2 = 'CLOUD_TYPE_2',
  CLOUD_TYPE_3 = 'CLOUD_TYPE_3',
}
interface ICloudComponent extends IDefaultResponsiveSupport {
  scrollPostion: number;
  cloudType: CLOUD_TYPES;
}
export default class CloudComponent extends React.Component<ICloudComponent> {
  private eleRef: React.RefObject<any>;
  private arry: string[] = [...Array(Math.floor(parseInt(styles.windowReelSize) / 800))].map(
    () => `${generateIntRandomNumber(0, 40)}%`
  );
  constructor(props: ICloudComponent) {
    super(props);
    this.eleRef = React.createRef();
  }

  shouldComponentUpdate(nextProps: ICloudComponent) {
    const { scrollPostion, responsiveMode, cloudType } = this.props;
    if (
      responsiveMode !== nextProps.responsiveMode ||
      cloudType !== nextProps.cloudType
    ) {
      return true;
    }
    if (scrollPostion !== nextProps.scrollPostion) {
      this.eleRef.current.scrollTo(nextProps.scrollPostion, 0);
    }
    return false;
  }

  private get clouds() {
    return this.arry.map((top, i) => {
      return (
        <div
          key={i}
          className={classnames(styles.cloudBox)}
          style={{
            top,
          }}
        >
          <div className={classnames(styles.cloud, 'cloud_common')} />
        </div>
      );
    });
  }

  render() {
    const { responsiveMode, cloudType } = this.props;
    return (
      <div
        ref={this.eleRef}
        className={classnames(
          'fullScreenWindow',
          styles.cloudContainer,
          cloudType,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div className={classnames('windowReel')}>{this.clouds}</div>
      </div>
    );
  }
}
