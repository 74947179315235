import React from 'react';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IPersonProps,
  IProfessionalSkills,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import PillComponent, {
  PillColors,
} from '../../../../components/pill/pill.component';
import styles from './skills.module.scss';

interface ISkillsComponent extends IDefaultResponsiveSupport {
  left: number;
  professionalSkills: IProfessionalSkills[];
  scrollPostion: number;
  personProps: IPersonProps;
}

interface ISkillsComponentState {
  runAnimation: boolean;
}

const pillColor = [
  PillColors.orange,
  PillColors.cyan,
  PillColors.blue,
  PillColors.green,
  PillColors.grey,
];
export default class SkillsComponent extends React.Component<
  ISkillsComponent,
  ISkillsComponentState
> {
  private eleRef: React.RefObject<any>;

  constructor(props: ISkillsComponent) {
    super(props);
    this.eleRef = React.createRef();
    this.state = {
      runAnimation: false,
    };
  }

  shouldComponentUpdate(
    nextProps: ISkillsComponent,
    nextState: ISkillsComponentState
  ) {
    const { scrollPostion, responsiveMode } = this.props;
    if (scrollPostion !== nextProps.scrollPostion && !this.state.runAnimation) {
      this.runAnimation(nextProps);
    }

    if (
      responsiveMode !== nextProps.responsiveMode ||
      this.state.runAnimation !== nextState.runAnimation
    ) {
      return true;
    }
    return false;
  }

  private runAnimation({
    personProps: { getBoundingClientRect: getPersonBoundingClientRect },
  }: ISkillsComponent) {
    const block = this.eleRef?.current?.getBoundingClientRect();
    if (
      getPersonBoundingClientRect?.right > block.left &&
      !this.state.runAnimation
    ) {
      this.setState({ runAnimation: true });
    }
  }

  render() {
    const { responsiveMode, left, professionalSkills } = this.props;
    let skillIndex = 0;
    return (
      <div
        ref={this.eleRef}
        style={{
          left,
        }}
        className={classnames(
          styles.skillsContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        {professionalSkills.map((skill, sIndex) => {
          return [
            this.getSkill(skill.title, ++skillIndex, PillColors.black, '25%'),
            skill.skills.map((item) =>
              this.getSkill(
                item.label,
                ++skillIndex,
                pillColor[sIndex % pillColor.length],
                this.heights[skillIndex % 3]
              )
            ),
          ];
        })}
      </div>
    );
  }

  private getSkill(
    label: string,
    index: number,
    color: PillColors,
    height: string
  ) {
    return (
      <div
        style={{
          left: 70 * index + 'px',
          animationDelay: index * 0.2 + 's',
          height,
        }}
        className={classnames('flex-col', styles.skillHanger, {
          [styles.runAnimation]: this.state.runAnimation 
        })}
        key={index}
      >
        <div className={classnames('size-auto', styles.rope)} />
        <PillComponent classNames={styles.pill} color={color}>
          {label}
        </PillComponent>
      </div>
    );
  }

  private get heights(): string[] {
    const { responsiveMode } = this.props;
    switch (responsiveMode) {
      case 'mobile':
        return  ['40%', '60%', '80%'];
      case 'tablet':
        return ['40%', '70%', '90%'];
      default:
        return ['40%', '70%', '90%'];
    }
  }
}
