import React from 'react';
import { PROJECTS_PADDING_LEFT } from '../../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IProjects,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './projects.module.scss';

interface IProjectsComponent extends IDefaultResponsiveSupport {
  left: number;
  projects: IProjects[];
}

export default class ProjectsComponent extends React.PureComponent<IProjectsComponent> {
  render() {
    const { responsiveMode, left, projects } = this.props;

    return (
      <div
        style={{
          left,
        }}
        className={classnames(
          styles.projectsContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        {projects.reverse().map((item, i) => this.getProject(item, i))}
      </div>
    );
  }

  private getProject(project: IProjects, index: number) {
    return (
      <div
        key={index}
        style={{
          left: PROJECTS_PADDING_LEFT * index,
        }}
        className={classnames('flex-col', styles.project)}
      >
        <p className={classnames(styles.title)}>Project #{index + 1}</p>
        <div className={classnames('size-4', styles.windowContainer)}>
          <div className={classnames('flex-row', styles.window)}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className={classnames('flex-col', 'size-8', styles.body)}>
          <p className={styles.period}>{project.period}</p>
          <p  className={styles.projectName}>{project.projectName}</p>
          <p  className={styles.projectOverview} dangerouslySetInnerHTML={{__html: project.projectOverview}}></p>
        </div>
      </div>
    );
  }
}
