import React from 'react';
import ReactDOM from 'react-dom';
import { classnames } from '../../common/general-utilities/general-utilities';
import ResponsiveModeService from '../../common/services/responsive-mode.service';
import styles from './drawer.module.scss';
import { IDefaultResponsiveSupport } from '../../common/interfaces/common.interface';

export enum DRAWER_DIRECTION {
  LEFT,
  BOTTOM,
}

interface IDrawerComponent extends IDefaultResponsiveSupport {
  isOpen: boolean;
  isDark: boolean;
  handleClose: () => void;
  direction?: DRAWER_DIRECTION;
  outerScopeContent?: JSX.Element;
}

export default class DrawerComponent extends React.Component<IDrawerComponent> {
  shouldComponentUpdate(nextProps: IDrawerComponent) {
    const { isOpen, isDark, outerScopeContent } = this.props;
    if (
      isOpen !== nextProps.isOpen ||
      isDark !== nextProps.isDark ||
      outerScopeContent !== nextProps.outerScopeContent
    ) {
      return true;
    }
    return false;
  }

  render() {
    return ReactDOM.createPortal(
      this.model,
      document.getElementById('drawer') as Element
    );
  }

  private hideModel = () => {
    const { handleClose } = this.props;
    handleClose && handleClose();
  };

  private get model() {
    const { responsiveMode, isDark, isOpen, direction, outerScopeContent } =
      this.props;
    return (
      <>
        {outerScopeContent}
        <div
          className={classnames(
            styles.drawerContainer,
            ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
            isOpen ? styles.open : styles.close,
            direction === DRAWER_DIRECTION.BOTTOM
              ? styles.directionBottom
              : styles.directionLeft,
            {
              [styles.isDark]: isDark,
            }
          )}
        >
          <div
            onClick={this.hideModel}
            className={classnames(styles.background)}
          ></div>
          <div className={classnames(styles.drawerContentContainer)}>
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}
