import React from 'react';
import { MenuSVG } from '../../common/constant/resume-data.constant';
import { classnames } from '../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../common/interfaces/common.interface';
import { ISetSideNavBarVisibility } from '../../context/action';
import styles from './side-nav-menu-icon.module.scss';

interface ISideNavMenuIconComponent extends IDefaultResponsiveSupport {
  style?: React.CSSProperties;
  setSideNavBarVisibility: ISetSideNavBarVisibility;
  isSideBarVisible: boolean;
  className?: string;
}

export default class SideNavMenuIconComponent extends React.Component<ISideNavMenuIconComponent> {
  shouldComponentUpdate() {
    return false;
  }

  private toggleSideNav = () => {
    const { isSideBarVisible, setSideNavBarVisibility } = this.props;
    setSideNavBarVisibility(!isSideBarVisible);
  };

  render() {
    const { style, className } = this.props;
    return (
      <div className={classnames(styles.sideNavMenuIconContainer)}>
        <button className={classnames(styles.iconButton)} onClick={this.toggleSideNav}>
          <MenuSVG
            style={style}
            title={'Menu'}
            className={classnames(styles.SVGIcon, className)}
          />
        </button>
      </div>
    );
  }
}
