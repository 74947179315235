import React from 'react';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IEducation,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './college-score-card.module.scss';

interface ICollegeScoreCard extends IDefaultResponsiveSupport {
  education: IEducation;
  runAnimation: boolean;
}

const sups: Record<number, string> = {
  1: 'st',
  2: 'nd',
  3: 'rd',
  4: 'th',
};

const sgpaPoints: Record<number, number[]> = {
  1: [130, 180],
  2: [240, 150],
  3: [350, 90],
  4: [460, 100],
  5: [560, 80],
  6: [670, 70],
  7: [780, 145],
  8: [890, 100],
};

const sgpaText: Record<number, number[]> = {
  1: [105, 200],
  2: [240, 170],
  3: [320, 75],
  4: [430, 80],
  5: [530, 65],
  6: [680, 65],
  7: [750, 165],
  8: [840, 88],
};

export default class CollegeScoreCard extends React.Component<ICollegeScoreCard> {
  
  render() {
    const { responsiveMode, education, runAnimation } = this.props;
    return (
      <div
        className={classnames(
          styles.collegeScoreCardContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          {
            [styles.showScoreCardAnim]: runAnimation,
          }
        )}
      >
        <div
          className={classnames('flex-col', styles.scoreCardLabelsContainer)}
        >
          <div className={classnames('size-auto', styles.title)}>
            <h1>
              {education.fullName}, {education.shortExam}({education.year})
            </h1>
          </div>
          <div
            className={classnames('size-auto', styles.semesterListContainer)}
          >
            <ul className={styles.semesterList}>
              <li>SGPA/CGPA</li>
              {education.semesters?.map((sem) => {
                return (
                  <li key={sem.semester}>
                    {sem.semester}
                    <sup>
                      {sups[sem.semester] ? sups[sem.semester] : 'th'}
                    </sup>{' '}
                    Sem
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={classnames('size-auto', styles.scoreCardContainer)}>
            <div
              className={classnames('flex-col', styles.scoreCardLabelContainer)}
            >
              <div>10</div>
              <div>9</div>
              <div>8</div>
            </div>
            <div className={classnames(styles.scoreCardSVGContainer)}>
              <svg
                viewBox='0 0 920 230'
                className={styles.svgContainer}
                xmlns='http://www.w3.org/2000/svg'
              >
                <polyline
                  className={classnames(styles.marksSGPAPath, {
                    [styles.drawPathAnim]: runAnimation,
                  })}
                  points={education.semesters
                    ?.map(
                      (sem) =>
                        `${sgpaPoints[sem.semester][0]},${sgpaPoints[sem.semester][1]
                        }`
                    )
                    .join(' ')}
                />
                {education.semesters?.map((sem) => (
                  <text
                    className={classnames(styles.sgpaText, {
                      [styles.fadeIn]: runAnimation,
                    })}
                    x={sgpaText[sem.semester][0]}
                    y={sgpaText[sem.semester][1]}
                    key={sem.semester}
                  >
                    {sem.result}
                  </text>
                ))}
                {education.semesters?.map((sem) => (
                  <circle
                    key={sem.semester}
                    className={classnames(styles.sgpaCircles, {
                      [styles.fadeIn]: runAnimation,
                    })}
                    cx={sgpaPoints[sem.semester][0]}
                    cy={sgpaPoints[sem.semester][1]}
                    r='5'
                  />
                ))}

                {/* CGPA */}
                <polyline
                  className={classnames(styles.marksCGPAPath, {
                    [styles.drawPathAnim]: runAnimation,
                  })}
                  points='130,120 890,120'
                />
                <circle
                  className={classnames(styles.cgpaCircles, {
                    [styles.fadeIn]: runAnimation,
                  })}
                  cx={130}
                  cy={120}
                  r='5'
                />
                <circle
                  className={classnames(styles.cgpaCircles, {
                    [styles.fadeIn]: runAnimation,
                  })}
                  cx={890}
                  cy={120}
                  r='5'
                />
                <text
                  className={classnames(styles.cgpaText, {
                    [styles.fadeIn]: runAnimation,
                  })}
                  x='860'
                  y='140'
                >
                  {education.result}
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
