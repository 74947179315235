import React from 'react';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IEducation,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './education.module.scss';

interface IEducationComponent extends IDefaultResponsiveSupport {
  education?: IEducation[];
}

export default class EducationComponent extends React.PureComponent<IEducationComponent> {
  render() {
    const { responsiveMode, education } = this.props;
    return (
      <div
        className={classnames(
          styles.educationContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <h1 className={classnames('resumeHeading1')}>Education</h1>
        {education?.map((item, i) => this.getEducatioinList(item, i))}
      </div>
    );
  }

  private getEducatioinList(
    education: IEducation,
    index: number
  ): JSX.Element | undefined {
    return (
      <div
        key={index}
        className={classnames('flex-row', styles.getEducatioinList, { lightBg: index % 2 === 0 })}
      >
        <div className={classnames('size-8', styles.exam)}>
          <p
            dangerouslySetInnerHTML={{
              __html: `${education.exam}, ${education.institute} (${education.year})`,
            }}
          ></p>
        </div>
        <div className={classnames('size-3', styles.result)}>
          <p>{education.result}</p>
        </div>
      </div>
    );
  }
}
