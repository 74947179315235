import React from 'react';
import { trainAvatar, VEHICLE_POSITION, VEHICLE_TYPE } from '../../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './train.module.scss';

interface ITrainComponent extends IDefaultResponsiveSupport {
  style?: React.CSSProperties;
  classes?: string[] | undefined;
  forwardRef?: React.RefObject<any>;
  isRotateBack: boolean;
  isVisible: boolean;
  isRunning?: boolean;
  type: VEHICLE_TYPE;
  position?: VEHICLE_POSITION;
}
export default class TrainComponent extends React.Component<ITrainComponent> {
  shouldComponentUpdate(prevProps: ITrainComponent) {
    if (
      this.props.isRotateBack !== prevProps.isRotateBack ||
      this.props.isVisible !== prevProps.isVisible ||
      this.props.isRunning !== prevProps.isRunning ||
      this.props.responsiveMode !== prevProps.responsiveMode
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      responsiveMode,
      style,
      classes,
      forwardRef,
      isRotateBack,
      isVisible,
      isRunning,
      type,
      position
    } = this.props;

    const classesProps: string[] = classes ? classes : [];
    return (
      <div
        ref={forwardRef}
        style={{
          backgroundImage: trainAvatar,
          ...style,
        }}
        className={classnames(
          styles.trainContainer,
          {
            [styles.rotateBack]: isRotateBack,
            [styles.hidden]: !isVisible,
            [styles.runTrain]: isRunning,
            [styles.enableScale]: type === VEHICLE_TYPE.EMPTY,
            [styles.positionLeft]: position === VEHICLE_POSITION.LEFT,
            [styles.positionRight]: position === VEHICLE_POSITION.RIGHT
          },
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          ...classesProps
        )}
      ></div>
    );
  }
}
