import React from 'react';

export class Title extends React.PureComponent {
  componentDidMount() {
    const { children } = this.props;
    document.title = children ? children?.toString() : '';
  }
  render() {
    return null;
  }
}
