import React from 'react';
import { IDefaultResponsiveSupport } from '../../common/interfaces/common.interface';
import {
  DarkModeSVG,
  iconHeaderLink,
  LightModeSVG,
  MenuSVG,
  Plain_Resume_Page_Title,
  ShareSVG,
} from '../../common/constant/resume-data.constant';
import styles from './plain-resume-page.module.scss';
import ResumeHeaderComponent from './resume-header/resume-header.component';
import ResponsiveModeService from '../../common/services/responsive-mode.service';
import AboutMeComponent from './about-me/about-me.component';
import {
  Context as GlobalContext,
  GlobalContextType,
} from '../../context/global.context';
import ProfessionalSkillsComponent from './professional-skills/professional-skills.component';
import EmploymentHistoryComponent from './employment-history/employment-history.component';
import ProjectsComponent from './projects/projects.component';
import EducationComponent from './education/education.component';
import CertificationsComponent from './certifications/certifications.component';
import {
  classnames,
  LinkMemo,
} from '../../common/general-utilities/general-utilities';
import { Title } from '../../components/title/title.component';

interface IPlainResume extends IDefaultResponsiveSupport {}

export default class PlainResumePageComponent extends React.PureComponent<IPlainResume> {
  static contextType = GlobalContext;

  componentWillUnmount() {
    const { resetInteractiveResumeState }: GlobalContextType = this.context;
    resetInteractiveResumeState && resetInteractiveResumeState();
  }

  render() {
    const { responsiveMode } = this.props;

    return (
      <div
        className={classnames(
          styles.plainResumeContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <Title>{Plain_Resume_Page_Title}</Title>
        <div className={styles.centerDiv}>
          {this.navigation}
          {this.resume}
          {this.footer}
        </div>
      </div>
    );
  }

  private setMode = () => {
    const {
      state: { isDark },
      setDarkMode,
    }: GlobalContextType = this.context;
    setDarkMode(!isDark);
  };

  private get navigation() {
    const {
      state: { resumeData },
    }: GlobalContextType = this.context;
    return (
      <div className={styles.navigation}>
        <h1 className={styles.name}>{resumeData?.aboutMe?.name}</h1>
        <div className={styles.headerIcons}>
          <ul>
            {iconHeaderLink.map((ele) => {
              return (
                <li key={ele.key}>
                  <LinkMemo className={styles.SVGIconLink} to={ele.link} title={ele.label}>
                    <ele.icon className={styles.SVGIcon} />
                  </LinkMemo>
                </li>
              );
            })}
            <li onClick={this.setMode}>{this.displayModeSVG}</li>
            <li onClick={this.openShareModal}>{this.shareIcon}</li>
            <li onClick={this.openSideNav}>{this.menuIcon}</li>
          </ul>
        </div>
      </div>
    );
  }

  private get footer() {
    const {
      state: { resumeData },
    }: GlobalContextType = this.context;
    return (
      <div className={styles.footer}>
        <h1 className={styles.name}>{resumeData?.aboutMe?.name}</h1>
        <div className={styles.headerIcons}>
          <ul>
            {iconHeaderLink.map((ele) => {
              return (
                <li key={ele.key}>
                  <LinkMemo exact to={ele.link} className={styles.SVGIconLink} title={ele.label}>
                    <ele.icon className={styles.SVGIcon} />
                  </LinkMemo>
                </li>
              );
            })}
            <li onClick={this.openShareModal}>{this.shareIcon}</li>
            <li onClick={this.openSideNav}>{this.menuIcon}</li>
          </ul>
        </div>
        <p className={styles.copyRight}>
          &#169; Right Resume. All rights reserved.
        </p>
        <p className={styles.copyRight}>
          Design by <b>Sunil Lohar</b>
        </p>
      </div>
    );
  }

  private get resume() {
    const { responsiveMode } = this.props;
    const {
      state: { resumeData },
    }: GlobalContextType = this.context;
    return (
      <div className={styles.resume}>
        <ResumeHeaderComponent
          name={resumeData?.aboutMe?.name}
          resumePath={resumeData?.resumePath}
          profileName={resumeData?.aboutMe?.profile}
          responsiveMode={responsiveMode}
          skill={resumeData?.aboutMe?.skill}
        />
        <div className={styles.main}>
          <AboutMeComponent
            aboutMe={resumeData?.aboutMe}
            responsiveMode={responsiveMode}
          />
          <div className={styles.divider} />
          <ProfessionalSkillsComponent
            professionalSkills={resumeData?.professionalSkills}
            responsiveMode={responsiveMode}
          />
          <EmploymentHistoryComponent
            employmentHistory={JSON.parse(
              JSON.stringify(resumeData?.employmentHistory)
            )}
            responsiveMode={responsiveMode}
          />
          <ProjectsComponent
            projects={JSON.parse(JSON.stringify(resumeData?.projects))}
            responsiveMode={responsiveMode}
          />
          <EducationComponent
            education={resumeData?.education}
            responsiveMode={responsiveMode}
          />
          <CertificationsComponent
            certifications={resumeData?.certifications}
            responsiveMode={responsiveMode}
          />
        </div>
      </div>
    );
  }

  private get displayModeSVG() {
    const {
      state: { isDark },
    }: GlobalContextType = this.context;

    return (
      <button className={styles.iconBtnContainer}>
        {isDark ? (
          <LightModeSVG className={styles.SVGIcon} title={'Light Mode'} />
        ) : (
          <DarkModeSVG className={styles.SVGIcon} title={'Dark Mode'} />
        )}
      </button>
    );
  }

  private openSideNav = () => {
    const { setSideNavBarVisibility }: GlobalContextType = this.context;
    setSideNavBarVisibility(true);
  };

  private openShareModal = () => {
    const { setShareModelVisibility }: GlobalContextType = this.context;
    setShareModelVisibility(true);
  };

  private get menuIcon() {
    return (
      <button className={styles.iconBtnContainer}>
        <MenuSVG className={styles.SVGIcon} title={'Menu'} />;
      </button>
    );
  }

  private get shareIcon() {
    return (
      <button className={styles.iconBtnContainer}>
        <ShareSVG className={styles.SVGIcon} title={'Share'} />;
      </button>
    );
  }
}
