import { SET_DARK_MODE } from '../../context/action-type-keys.constant';
import { CLOUD_TYPES } from '../../pages/interactive-resume/cloud/cloud.component';
import { Directions } from '../constant/interactive.resume.constant';

export interface Layout {
  width: number;
  height: number;
}
export type ResponsiveMode = 'mobile' | 'tablet' | 'desktop';

export interface IDefaultResponsiveSupport {
  responsiveMode?: ResponsiveMode;
}

export interface ISkills {
  label: string;
  tooltip?: string;
  position: string;
}
export interface IProfessionalSkills {
  title: string;
  skills: ISkills[];
}

export interface IProjects {
  organisation: string;
  projectName: string;
  projectOverview: string;
  period: string;
  role: string;
  responsibilities: string[];
}
export interface IEmploymentHistory {
  organisation: string;
  location: string;
  designation: string;
  period: string;
  shortPeriod: string;
}

export interface IEducation {
  exam: string;
  shortExam?: string;
  result: string;
  institute: string;
  fullName?: string;
  year: string;
  semesters?: {
    semester: number;
    result: string;
  }[];
}

export interface IContactInfo {
  DOB: string;
  Mobile: string;
  Email: string;
  Address: string;
}

export interface IAboutMe {
  summary: string;
  contactInfo: IContactInfo;
  name: string;
  designation: string;
  profile: string;
  skill: string;
}

export interface IResumeData {
  aboutMe: IAboutMe;
  professionalSkills: IProfessionalSkills[];
  employmentHistory: IEmploymentHistory[];
  projects: IProjects[];
  education: IEducation[];
  certifications: string[];
  resumePath: string;
}
export interface IScrollPositions {
  platform: number;
  cloud: number;
  mountainAndCity: number;
}

export interface IBoundingClientRect {
  left: number;
  right: number;
  width: number;
  height: number;
}

export interface IPersonProps {
  direction: Directions.ArrowLeft | Directions.ArrowRight;
  ref: React.RefObject<any>;
  shadow: React.RefObject<any>;
  isRunning: boolean;
  makeJump: boolean;
  nextPlatform: React.RefObject<any> | null;
  currentPlatform: React.RefObject<any> | null;
  isVisible: boolean;
  getBoundingClientRect: IBoundingClientRect;
}

export interface IPersonOnObjectProps {
  ref: React.RefObject<any>;
  isVisible: boolean;
  getBoundingClientRect: IBoundingClientRect;
}

export interface ISky {
  backgroundColor: string;
  cloudType: CLOUD_TYPES;
  isStarVisible: boolean;
}

export enum SCREEN_ORIENTATION {
  LANDSCAPE = 'landscape-primary',
  PORTRAIT = 'portrait-primary',
}

export const SCREEN_ORIENTATION_LABEL: Record<SCREEN_ORIENTATION, string> = {
  [SCREEN_ORIENTATION.LANDSCAPE]: 'Landscape Mode',
  [SCREEN_ORIENTATION.PORTRAIT]: 'Portrait Mode',
};

export interface IScreen {
  isFullScreen: boolean;
  orientation: SCREEN_ORIENTATION;
}

export interface IPWA {
  newVersionAvailable: boolean;
  waitingWorker: ServiceWorkerRegistration['waiting'];
  installPromptEvent: any;
}

export interface IGlobalState {
  layout: Layout;
  isDark: boolean;
  responsiveMode: ResponsiveMode;
  resumeData: Partial<IResumeData>;
  isLoading: boolean;
  scrollPostions: IScrollPositions;
  personProps: IPersonProps;
  shouldMoveScrolls: boolean;
  personOnBoatProps: IPersonOnObjectProps;
  personOnTrainProps: IPersonOnObjectProps;
  personInCarProps: IPersonOnObjectProps;
  sky: ISky;
  isSideBarVisible: boolean;
  screen: IScreen;
  pwa: IPWA;
  isShareModelVisible: boolean;
}

export const LOCAL_STORAGE_KEY: Record<string, string> = {
  [SET_DARK_MODE]: 'isDark',
};

export enum PAGE_ROUTES {
  HOME = '/',
  PLAIN_RESUME = '/portfolio',
  INTERACTIVE_RESUME = '/interactive-resume',
}

export enum NAV_EVENT_TYPE {
  HOME,
  PLAIN_RESUME,
  INTERACTIVE_RESUME,
  DARK_LIGHT_MODE,
  ROTATE_SCREEN,
  FULL_SCREEN_ON_OFF,
  CANCEL,
}