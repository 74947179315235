import React from 'react';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './gate.module.scss';

interface IGateComponent extends IDefaultResponsiveSupport {
    left?: number;
    lightColor?: string;
    darkColor?: string;
    title?: string;
    subTitle?: string;
}
export default class GateComponent extends React.PureComponent<IGateComponent> {
    public static defaultProps = {
        left: 0,
        lightColor: styles.red10,
        darkColor: styles.red20,
        title: 'Title',
        subTitle: 'subTitle'
    }
    render() {
        const { responsiveMode, left, darkColor, lightColor, title, subTitle } = this.props;
        return (
            <div style={{
                left
            }} className={classnames(styles.gateContainer, ResponsiveModeService.responsiveModeClass(styles, responsiveMode))}>
                <div className={classnames('flex-row')}>
                    <div className={classnames('size-11', 'flex-col')}>
                        <div style={{backgroundColor: lightColor}} className={classnames('size-4','flex-col', styles.heading)}>
                            <h1 className={classnames('size-auto', styles.title)}>{title}</h1>
                            <p className={classnames('size-auto', styles.subTitle)}>{subTitle}</p>
                        </div>
                        <div className={classnames('size-8', 'flex-row')}>
                            <div style={{backgroundColor: lightColor}} className={classnames('size-1',styles.minWidth40)}></div>
                            <div style={{backgroundColor: darkColor}} className={classnames('size-1', styles.minWidth40)}></div>
                            <div className={classnames('size-9')}></div>
                            <div style={{backgroundColor: lightColor}} className={classnames('size-1', styles.minWidth40)}></div>
                        </div>
                    </div>
                    <div style={{
                        backgroundColor: darkColor
                    }} className={classnames('size-1', styles.minWidth40)}></div>
                </div>
            </div>
        );
    }
}