import React from 'react';
import { languageIconsAvatar } from '../../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './skill.module.scss';

interface ISkillComponent extends IDefaultResponsiveSupport {
  label: string;
  position: string;
  tooltip?: string;
}
export default class SkillComponent extends React.PureComponent<ISkillComponent> {
  render() {
    const { responsiveMode, label, position, tooltip } = this.props;
    return (
      <div
        className={classnames(
          styles.skillComponent,
          'tooltip',
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div
          className={styles.iconAvatar}
          style={{
            backgroundImage: languageIconsAvatar,
            backgroundPosition: position,
          }}
        />
        <p className={styles.skillLabel}>{label}</p>
        {tooltip ? (
          <span className={classnames('tooltiptext')}>
            {this.props.tooltip}
          </span>
        ) : null}
      </div>
    );
  }
}
