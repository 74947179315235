import React from 'react';
import { Directions, VEHICLE_TYPE } from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IPersonOnObjectProps,
  IDefaultResponsiveSupport,
  IPersonProps,
  Layout,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import BoatComponent from '../above-platform/boat/boat.component';
import { ISetPersonOnBoatProps } from '../../../context/action';
import styles from './person-on-boat.module.scss';

interface IPersonOnBoatComponent extends IDefaultResponsiveSupport {
  layout: Layout;
  personProps: IPersonProps;
  personOnBoatProps: IPersonOnObjectProps;
  setPersonOnBoatProps: ISetPersonOnBoatProps;
}
export default class PersonOnBoatComponent extends React.Component<IPersonOnBoatComponent> {
  private fixPosition({
    layout: { width: layoutWidth },
    personOnBoatProps: { ref },
    setPersonOnBoatProps,
  }: IPersonOnBoatComponent) {
    if (ref && ref.current) {
      ref.current.style.left = `${layoutWidth / 2 - ref.current.offsetWidth / 2}px`;
      const { left, right, width, height } =
        ref?.current?.getBoundingClientRect();
      setPersonOnBoatProps({
        getBoundingClientRect: {
          left,
          right,
          width,
          height,
        },
      });
    }
  }

  shouldComponentUpdate(nextProps: IPersonOnBoatComponent) {
    const { responsiveMode, personProps, layout } = this.props;
    if (
      layout.width !== nextProps.layout.width ||
      layout.height !== nextProps.layout.height
    ) {
      this.fixPosition(nextProps);
      return false;
    }

    if (
      personProps.direction !== nextProps.personProps.direction ||
      personProps.isRunning !== nextProps.personProps.isRunning ||
      responsiveMode !== nextProps.responsiveMode ||
      personProps.isVisible !== nextProps.personProps.isVisible
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.fixPosition(this.props);
  }

  render() {
    const { responsiveMode, personProps, personOnBoatProps } = this.props;

    return (
      <div
        ref={personOnBoatProps.ref}
        className={classnames(
          styles.personOnBoatContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          {
            [styles.hidden]: !personOnBoatProps.isVisible,
          }
        )}
      >
        <BoatComponent
          style={{
            backgroundPositionX: '-350px',
          }}
          responsiveMode={responsiveMode}
          isRotateBack={personProps.direction === Directions.ArrowLeft}
          isVisible={true}
          type={VEHICLE_TYPE.FULL}
        />
      </div>
    );
  }
}
