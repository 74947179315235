import React from 'react';
import {
  classnames,
  generateIntRandomNumber,
} from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './sky.module.scss';

interface ISkyComponent extends IDefaultResponsiveSupport {
  backgroundColor: string;
  isStarVisible: boolean;
}
export default class SkyComponent extends React.PureComponent<ISkyComponent> {
  private arr = [...Array(200)].map((_item, i) => {
    const left = generateIntRandomNumber(1, 99) + '%';
    const top = generateIntRandomNumber(1, 99) + '%';
    const animationDelay = generateIntRandomNumber(0, 10) + 's';
    return {
      left,
      top,
      animationDelay,
      width: i % 5 === 0 ? '2px' : '',
      height: i % 5 === 0 ? '2px' : '',
    };
  });
  render() {
    const { responsiveMode, backgroundColor, isStarVisible } = this.props;
    return (
      <div
        style={{
          backgroundColor,
        }}
        className={classnames(
          'fullScreenWindow',
          styles.skyContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div
          className={classnames(styles.stars, {
            [styles.starAnim]: isStarVisible,
          })}
        >
          {this.arr.map((item, i) => {
            return (
              <div
                style={{
                  top: item.top,
                  left: item.left,
                  animationDelay: item.animationDelay,
                  width: item.width,
                  height: item.height,
                }}
                key={i}
                className={classnames(styles.star)}
              ></div>
            );
          })}
        </div>
      </div>
    );
  }
}
