import React from 'react';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './board.module.scss';

interface IBoardComponent extends IDefaultResponsiveSupport {
  lightColor: string;
  darkColor: string;
  textColor: string;
  justifyContent?:
    | 'space-evenly'
    | 'space-around'
    | 'space-between'
    | 'flex-start';
}
export default class BoardComponent extends React.Component<IBoardComponent> {
  static defaultProps = {
    justifyContent: 'space-evenly',
  };

  shouldComponentUpdate(nextProps: IBoardComponent) {
    const { responsiveMode } = this.props;
    if (responsiveMode !== nextProps.responsiveMode) {
      return true;
    }
    return false;
  }

  render() {
    const { responsiveMode, lightColor, darkColor, textColor, justifyContent } =
      this.props;
    return (
      <div
        className={classnames(
          styles.boardContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div className={classnames('flex-row', styles.verticalPole)}>
          <div
            style={{
              backgroundColor: darkColor,
            }}
            className={classnames('size-auto')}
          ></div>
          <div
            style={{
              backgroundColor: lightColor,
            }}
            className={classnames('size-auto')}
          ></div>
        </div>
        <div
          style={{
            justifyContent,
            filter: 'drop-shadow(-1px 4px 2px #00000024)',
          }}
          className={classnames(styles.children)}
        >
          {React.Children.map(this.props.children, (child) => (
            <div
              className={classnames(styles.child, {
                [styles.borderRadius]: justifyContent !== 'flex-start',
              })}
              style={{
                backgroundColor: lightColor,
                color: textColor,
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
