import React from 'react';
import {
  BLOCKS_NAME,
  Directions,
  PersonAvatar,
} from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IPersonProps,
  Layout,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import { ISetPersonProps } from '../../../context/action';
import styles from './person.module.scss';

interface IPersonComponent extends IDefaultResponsiveSupport {
  layout: Layout;
  personProps: IPersonProps;
  setPersonProps: ISetPersonProps;
}

interface IPersonComponentState {
  isBreathing: boolean;
  message: string;
}
export default class PersonComponent extends React.Component<
  IPersonComponent,
  IPersonComponentState
> {
  public state: IPersonComponentState = {
    isBreathing: true,
    message: '',
  };
  private mgsIndex = 0;
  private charIndex = 0;
  private speed = 40;
  private timeoutID: any;
  private textToDisplay: string[] = [
    `Hi, My name is Sunil Lohar & I build things on internet.`,
    `This website combines resume and video game.`,
  ];

  private fixPosition({
    layout: { width: layoutWidth },
    personProps: { ref, shadow },
    setPersonProps,
  }: IPersonComponent) {
    if (ref) {
      ref.current.style.left = `${
        layoutWidth / 2 - ref.current.offsetWidth / 2
      }px`;
      const { left, right, width, height } =
        ref?.current?.getBoundingClientRect();
      setPersonProps({
        getBoundingClientRect: {
          left,
          right,
          width,
          height,
        },
      });

      if (shadow && shadow.current) {
        shadow.current.style.left = left - 10 + 'px';
        shadow.current.style.width = width + 'px';
      }
    }
  }

  private fixPlatform({
    personProps: {
      ref,
      currentPlatform,
      nextPlatform,
      shadow,
      getBoundingClientRect: getPersonBoundingClientRect,
    },
  }: IPersonComponent) {
    if (currentPlatform === null) {
      ref.current.style.bottom = '';
      shadow.current.style.width = getPersonBoundingClientRect.width + 'px';
    } else if (nextPlatform !== null) {
      ref.current.style.bottom = `${nextPlatform?.current?.offsetHeight}px`;
      shadow.current.style.width = '0';
    }
  }

  shouldComponentUpdate(
    nextProps: IPersonComponent,
    nextState: IPersonComponentState
  ) {
    const { layout, personProps, responsiveMode } = this.props;
    if (
      layout.width !== nextProps.layout.width ||
      layout.height !== nextProps.layout.height
    ) {
      this.fixPosition(nextProps);
      this.fixPlatform(nextProps);
    }

    if (
      personProps.makeJump !== nextProps.personProps.makeJump &&
      nextProps.personProps.makeJump
    ) {
      this.jump(nextProps);
      return false;
    }

    if (personProps.currentPlatform !== nextProps.personProps.currentPlatform) {
      this.fixPlatform(nextProps);
      return false;
    }

    if (
      personProps.direction !== nextProps.personProps.direction ||
      personProps.isRunning !== nextProps.personProps.isRunning ||
      responsiveMode !== nextProps.responsiveMode ||
      this.state.isBreathing !== nextState.isBreathing ||
      personProps.isVisible !== nextProps.personProps.isVisible ||
      this.state.message !== nextState.message
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.fixPosition(this.props);
  }

  private jump = ({
    personProps: { ref, nextPlatform, shadow },
    setPersonProps,
  }: IPersonComponent) => {
    if (ref) {
      this.setState({ isBreathing: false }, () => {
        ref.current.style.bottom = `${150}px`;
        ref.current.style.backgroundPosition = `-767px 0px`;
        shadow.current.style.transform = 'scale(0.4)';
        setTimeout(() => {
          const nextPlatformName =
            nextPlatform?.current?.getAttribute('data-name');
          ref.current.style.bottom =
            nextPlatform && nextPlatformName !== BLOCKS_NAME.FINISH_BLOCK
              ? `${nextPlatform.current.offsetHeight}px`
              : '';
          ref.current.style.backgroundPosition = ``;
          shadow.current.style.transform = '';
          this.setState({ isBreathing: true }, () => {
            setPersonProps({
              currentPlatform:
                nextPlatformName !== BLOCKS_NAME.FINISH_BLOCK
                  ? nextPlatform
                  : null,
            });
          });
        }, 300);
      });
    }
  };

  render() {
    const { responsiveMode, personProps } = this.props;
    return (
      <>
        <div
          ref={personProps.ref}
          className={classnames(
            styles.personContainer,
            ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
            {
              [styles.hidden]: !personProps.isVisible,
            }
          )}
        >
          {false && (
            <div
              className={classnames(styles.speechBoxContainer, {
                [styles.isLeftDirection]:
                  personProps.direction === Directions.ArrowLeft,
              })}
            >
              <span>
                {this.state.message}
                <span className={classnames(styles.pointer)}>|</span>
              </span>
            </div>
          )}
          <div
            style={{
              backgroundImage: PersonAvatar,
            }}
            className={classnames(styles.person, {
              [styles.breath]: this.state.isBreathing,
              [styles.runCharacter]: personProps.isRunning,
              [styles.rotateBack]:
                personProps.direction === Directions.ArrowLeft,
            })}
          ></div>
        </div>
        <div
          ref={personProps.shadow}
          className={classnames(styles.shadow, {
            [styles.hidden]: !personProps.isVisible,
          })}
        ></div>
      </>
    );
  }

  private typeWriter = (isClearMessage = false) => {
    if (
      this.mgsIndex < this.textToDisplay.length &&
      this.charIndex < this.textToDisplay[this.mgsIndex].length
    ) {
      this.setState(
        (state) => ({
          message: isClearMessage
            ? this.textToDisplay[this.mgsIndex].charAt(this.charIndex)
            : state.message +
              this.textToDisplay[this.mgsIndex].charAt(this.charIndex),
        }),
        () => {
          this.charIndex++;
          this.timeoutID = setTimeout(this.typeWriter, this.speed);
        }
      );
    } else if (this.mgsIndex < this.textToDisplay.length) {
      this.mgsIndex++;
      this.charIndex = 0;
      this.timeoutID = setTimeout(this.typeWriter.bind(this, true), 1000);
    }
  };

  componentWillUnmount() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
  }
}
