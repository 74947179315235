import React from 'react';
import {
  DocumentSVG,
  Home_Page_Title,
  PersonSVG,
  ShareSVG,
} from '../../common/constant/resume-data.constant';
import {
  classnames,
  LinkMemo,
} from '../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  PAGE_ROUTES,
} from '../../common/interfaces/common.interface';
import ResponsiveModeService from '../../common/services/responsive-mode.service';
import { Title } from '../../components/title/title.component';
import SkyComponent from '../interactive-resume/sky/sky.component';
import styles from './home-page.module.scss';
import {
  Context as GlobalContext,
  GlobalContextType,
} from '../../context/global.context';
import BirdsComponent from '../interactive-resume/birds/birds.component';
import { grayGroundAvatar } from '../../common/constant/interactive.resume.constant';
import PersonComponent from '../interactive-resume/person/person.component';
import MobileNavigationComponent from '../interactive-resume/mobile-navigation/mobile-navigation.component';
import SunComponent from '../interactive-resume/sun/sun.component';
import SideNavMenuIconComponent from '../../components/side-nav-menu-icon/side-nav-menu-icon.component';

interface IHome extends IDefaultResponsiveSupport {}
export default class HomePageComponent extends React.PureComponent<IHome> {
  static contextType = GlobalContext;

  componentWillUnmount() {
    const { resetInteractiveResumeState }: GlobalContextType = this.context;
    resetInteractiveResumeState && resetInteractiveResumeState();
  }

  private get footer() {
    const {
      state: { resumeData },
    }: GlobalContextType = this.context;
    return (
      <div className={styles.footer}>
        <p className={styles.copyRight}>
          &#169; Right Resume. All rights reserved.
        </p>
        <p className={styles.copyRight}>
          Design by <b>{resumeData?.aboutMe?.name}</b>
        </p>
      </div>
    );
  }

  private get shareButtonIcon() {
    const { setShareModelVisibility }: GlobalContextType = this.context;
    return (
      <button
        onClick={setShareModelVisibility.bind(this, true)}
        className={classnames(styles.iconButton, styles.shareButtonIcon)}
      >
        <ShareSVG title={'Share'} className={classnames(styles.SVGIcon)} />
      </button>
    );
  }

  private get interactiveOverviewContainer() {
    const { responsiveMode } = this.props;
    const {
      state: { layout, personProps, isDark },
      setPersonProps,
    }: GlobalContextType = this.context;

    return (
      <div
        className={classnames(
          'flex-col',
          styles.interactiveResumeOverviewContainer
        )}
      >
        <div className={classnames('size-9', styles.topBox)}>
          <SkyComponent
            isStarVisible={isDark}
            backgroundColor={isDark ? styles.skyColor4 : styles.skyColor1}
            responsiveMode={responsiveMode}
          />
          <SunComponent isNighMode={isDark} responsiveMode={responsiveMode} />
          <BirdsComponent responsiveMode={responsiveMode} />
          <div
            style={{ opacity: isDark ? 0 : 1 }}
            className={classnames(styles.lightModebackgroundImages)}
          ></div>
          <div
            style={{ opacity: isDark ? 1 : 0 }}
            className={classnames(styles.darkModebackgroundImages)}
          ></div>
          <PersonComponent
            layout={layout}
            personProps={personProps}
            responsiveMode={responsiveMode}
            setPersonProps={setPersonProps}
          />
        </div>
        <div className={classnames('size-3', styles.bottomBox)}>
          <MobileNavigationComponent
            scrollPostion={101}
            responsiveMode={responsiveMode}
          />
          <div
            style={{
              opacity: isDark ? 0 : 1,
            }}
            className={classnames(styles.platform, styles.aboutMePlatForm)}
          ></div>
          <div
            style={{
              backgroundImage: grayGroundAvatar,
              opacity: isDark ? 1 : 0,
            }}
            className={classnames(styles.platform, styles.workProfilePlatForm)}
          ></div>
        </div>
      </div>
    );
  }

  private get navigationContainer() {
    const { responsiveMode } = this.props;
    const {
      state: { resumeData },
    }: GlobalContextType = this.context;

    return (
      <div
        className={classnames(
          styles.navigationContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        {this.footer}
        <div className={classnames(styles.content)}>
          <h1>
            Hello, I'm <span>{resumeData?.aboutMe?.name}</span>
          </h1>
          <h1>I'm a {resumeData?.aboutMe?.profile}</h1>
          <div className={classnames(styles.navBtns)}>
            <LinkMemo
              className={classnames(styles.outlineBtn)}
              to={PAGE_ROUTES.PLAIN_RESUME}
            >
              <DocumentSVG /> Portfolio
            </LinkMemo>
            <LinkMemo
              className={classnames(
                styles.outlineBtn,
                styles.interactiveResumeBtn
              )}
              to={PAGE_ROUTES.INTERACTIVE_RESUME}
            >
              <PersonSVG />
              Interactive Resume
            </LinkMemo>
          </div>
        </div>
      </div>
    );
  }

  private get sideNavButton() {
    const { responsiveMode } = this.props;
    const {
      state: { isSideBarVisible },
      setSideNavBarVisibility,
    }: GlobalContextType = this.context;

    return (
      <SideNavMenuIconComponent
        isSideBarVisible={isSideBarVisible}
        setSideNavBarVisibility={setSideNavBarVisibility}
        responsiveMode={responsiveMode}
        className={styles.homePageSideBarMenuIcon}
      />
    );
  }

  render() {
    const { responsiveMode } = this.props;

    return (
      <>
        <Title>{Home_Page_Title}</Title>
        <div
          className={classnames(
            styles.homeContainer,
            ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
          )}
        >
          {this.sideNavButton}

          {this.shareButtonIcon}

          {this.interactiveOverviewContainer}

          {this.navigationContainer}
        </div>
      </>
    );
  }
}
