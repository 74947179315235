import { ReactComponent as homepage } from "../../assets/svg/homepage.svg";
import { ReactComponent as darkMode } from "../../assets/svg/darkMode.svg";
import { ReactComponent as lightMode } from "../../assets/svg/lightMode.svg";
import { ReactComponent as menu } from "../../assets/svg/menu.svg";

import { ReactComponent as document } from "../../assets/svg/document.svg";
import { ReactComponent as fullscreen } from "../../assets/svg/fullscreen.svg";
import { ReactComponent as fullscreenExit } from "../../assets/svg/fullscreen_exit.svg";
import { ReactComponent as person } from "../../assets/svg/person.svg";
import { ReactComponent as screenRotation } from "../../assets/svg/screen_rotation.svg";
import { ReactComponent as cancel } from "../../assets/svg/cancel.svg";
import { ReactComponent as check } from "../../assets/svg/check.svg";
import { ReactComponent as download } from "../../assets/svg/download.svg";
import { ReactComponent as share } from "../../assets/svg/share.svg";
import { ReactComponent as copy } from "../../assets/svg/copy.svg";

import React from "react";
import { PAGE_ROUTES } from "../interfaces/common.interface";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

export const iconHeaderLink = [
  {
    label: "Home",
    link: PAGE_ROUTES.HOME,
    key: 1,
    icon: homepage,
  },
  {
    label: "Interactive Resume",
    link: PAGE_ROUTES.INTERACTIVE_RESUME,
    key: 2,
    icon: person,
  },
];

export const informationMark = "&#9432;";

export const LightModeSVG = React.memo(lightMode, () => true);
export const DarkModeSVG = React.memo(darkMode, () => true);
export const MenuSVG = React.memo(menu, () => true);

export const HomepageSVG = React.memo(homepage, () => true);
export const DocumentSVG = React.memo(document, () => true);
export const FullscreenSVG = React.memo(fullscreen, () => true);
export const FullscreenExitSVG = React.memo(fullscreenExit, () => true);
export const PersonSVG = React.memo(person, () => true);
export const ScreenRotationSVG = React.memo(screenRotation, () => true);
export const CancelSVG = React.memo(cancel, () => true);
export const CheckSVG = React.memo(check, () => true);
export const DownloadSVG = React.memo(download, () => true);
export const ShareSVG = React.memo(share, () => true);
export const CopySVG = React.memo(copy, () => true);

export const Home_Page_Title = "Sunil Lohar | Home";
export const Plain_Resume_Page_Title = "Sunil Lohar | Portfolio";
export const Interactive_Resume_Page_Title = "Sunil Lohar | Interactive Resume";

export const shareLinks = [
  {
    Button: WhatsappShareButton,
    Icon: WhatsappIcon,
  },
  {
    Button: FacebookShareButton,
    Icon: FacebookIcon,
  },
  {
    Button: TwitterShareButton,
    Icon: TwitterIcon,
  },
  {
    Button: LinkedinShareButton,
    Icon: LinkedinIcon,
  },
  {
    Button: EmailShareButton,
    Icon: EmailIcon,
  },
  {
    Button: null,
    Icon: CopySVG,
  },
];
