import {
  IAddScroll,
  ISetPersonProps,
} from '../../context/action';
import { scrollBy } from '../constant/interactive.resume.constant';
import {
  ScrollWindowList,
  Directions,
} from '../constant/interactive.resume.constant';

export default class AnimationService {
  private static addScroll: IAddScroll;
  private static setPersonProps: ISetPersonProps;
  private static runAnimationRef: NodeJS.Timeout;

  public static bootstrap(
    addScroll: IAddScroll,
    setPersonProps: ISetPersonProps
  ) {
    this.addScroll = addScroll;
    this.setPersonProps = setPersonProps;
    this.registerKeyEvent();
  }

  private static runAnimation(direction: Directions) {
    if (direction === Directions.ArrowRight || direction === Directions.ArrowLeft) {
      clearInterval(this.runAnimationRef);
      this.runAnimationRef = setInterval(() => {
        this.moveWindowScroll(direction === Directions.ArrowRight ? 1 : -1);
        this.setPersonProps({ direction, isRunning: true, makeJump: false });
      },50);
    }
  }

  private static moveWindowScroll(direction: 1 | -1) {
    this.addScroll({
      key: ScrollWindowList.PLATFORM,
      value: direction * scrollBy[ScrollWindowList.PLATFORM],
    });
    this.addScroll({
      key: ScrollWindowList.CLOUD,
      value: direction * scrollBy[ScrollWindowList.CLOUD],
    });
    this.addScroll({
      key: ScrollWindowList.MOUNTAIN_AND_CITY,
      value: direction * scrollBy[ScrollWindowList.MOUNTAIN_AND_CITY],
    });
  }

  private static registerKeyEvent() {
    let keysdown: {
      [Property in Directions]?: boolean;
    } = {};

    document.addEventListener('keydown', (e: KeyboardEvent) => {
      const direction = e.key as Directions;
      if (keysdown[direction]) {
        return;
      }
      keysdown[direction] = true;

      this.keyDown(direction);
    });

    document.addEventListener('keyup', (e: KeyboardEvent) => {
      const direction = e.key as Directions;
      delete keysdown[direction];
      this.keyUp(direction);
    });

    window.addEventListener('blur', () => {
      keysdown = {};
      clearInterval(this.runAnimationRef);
      this.setPersonProps({ isRunning: false });
    });
  }

  public static keyDown(direction: Directions) {
    if (direction === Directions.ArrowRight || direction === Directions.ArrowLeft) {
      this.runAnimation(direction);
    } else if (direction === Directions.ArrowUp) {
      this.setPersonProps({ isRunning: false, makeJump: true });
    }
  }

  public static keyUp(direction: Directions) {
    if (direction === Directions.ArrowRight || direction === Directions.ArrowLeft) {
      clearInterval(this.runAnimationRef);
      this.setPersonProps({ isRunning: false });
    } else if (direction === Directions.ArrowUp) {
      this.setPersonProps({ makeJump: false });
    }
  }
}
