import React from 'react';
import { classnames } from '../../../../../common/general-utilities/general-utilities';
import {
  IAboutMe,
  IDefaultResponsiveSupport,
} from '../../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../../common/services/responsive-mode.service';
import PullyComponent from '../../../../../components/pulley/pulley.component';
import styles from './tv.module.scss';

interface ITVComponent extends IDefaultResponsiveSupport {
  isAnimated: boolean;
  aboutMe: IAboutMe;
}

interface ITVComponentState {
  text: string;
}
export default class TVComponent extends React.PureComponent<
  ITVComponent,
  ITVComponentState
> {
  private index = 0;
  private speed = 30;
  private timeoutID: any;
  constructor(props: ITVComponent) {
    super(props);
    this.state = {
      text: '',
    };
    this.typeWriter = this.typeWriter.bind(this);
  }

  private get textToDisplay(): string {
    const { aboutMe } = this.props;
    return `
    <Name>${aboutMe?.name}</Name>
    <Profession>
       ${aboutMe?.profile}
    </Profession>
    <Exp>${new Date().getFullYear() - 2014}+ Years </Exp>
    <MO>${aboutMe?.contactInfo?.Mobile}</MO>`;
  }

  private typeWriter() {
    if (this.index < this.textToDisplay.length) {
      this.setState(
        (state) => ({
          text: state.text + this.textToDisplay.charAt(this.index),
        }),
        () => {
          this.index++;
          this.timeoutID = setTimeout(this.typeWriter, this.speed);
        }
      );
    }
  }

  componentDidUpdate(preProps: ITVComponent) {
    const { isAnimated } = this.props;
    if (preProps.isAnimated !== isAnimated && isAnimated) {
      setTimeout(this.typeWriter, 700);
    }
  }

  componentWillUnmount() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
  }

  render() {
    const { responsiveMode, isAnimated } = this.props;
    return (
      <div
        className={classnames(
          'flex-col',
          styles.tvContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          {
            [styles.moveDown]: isAnimated,
          }
        )}
      >
        <div className={classnames('size-auto', styles.chain)}>
          <p>About Me</p>
        </div>
        <PullyComponent
          style={{
            top: '-15px',
          }}
        />
        <div className={classnames(styles.tvBody)}>
          <pre className={classnames(styles.code)}>
            {this.state.text}
            <span className={classnames(styles.pointer)}>|</span>
          </pre>
        </div>
      </div>
    );
  }
}
