import React from 'react';
import { classnames } from '../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../common/interfaces/common.interface';
import ResponsiveModeService from '../../common/services/responsive-mode.service';
import styles from './loader.module.scss';

interface ILoader extends IDefaultResponsiveSupport {
}

export default class LoaderComponent extends React.PureComponent<ILoader> {
    render() {
        const { responsiveMode } = this.props;
        return (
            <div className={classnames(styles.loaderContainer, ResponsiveModeService.responsiveModeClass(styles, responsiveMode))}>
                <div className={styles.loader}></div>
            </div>
        );
    }
}