import React from 'react';
import { classnames } from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './certifications.module.scss';

interface ICertificationsComponent extends IDefaultResponsiveSupport {
    certifications?: string[]
}

export default class CertificationsComponent extends React.PureComponent<ICertificationsComponent> {
    render() {
        const { responsiveMode, certifications } = this.props;
        return (
            <div className={classnames(styles.certificationsContainer, ResponsiveModeService.responsiveModeClass(styles, responsiveMode))}>
                <h1 className={classnames('resumeHeading1')}>Certifications</h1>
                <ul>
                    {certifications?.map((item, i) => <li key={i}><p>{item}</p></li>)}
                </ul>
            </div>
        );
    }
}