export const SET_LAYOUT_SIZE = 'SET_LAYOUT_SIZE';
export const SET_RESPONSIVE_MODE = 'SET_RESPONSIVE_MODE';
export const SET_RESUME_DATA = 'SET_RESUME_DATA';
export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_SCROLL_TO = 'SET_SCROLL_TO';
export const SET_PERSON_PROPS = 'SET_PERSON_PROPS';
export const SET_PERSON_ON_BOAT_PROPS = 'SET_PERSON_ON_BOAT_PROPS';
export const SET_PERSON_IN_CAR_PROPS = 'SET_PERSON_IN_CAR_PROPS';
export const SET_PERSON_ON_TRAIN_PROPS = 'SET_PERSON_ON_TRAIN_PROPS';
export const SET_SHOULD_MOVE_SCROLLS = 'SET_SHOULD_MOVE_SCROLLS';
export const RESET_INTERACTIVE_RESUME_STATE = 'RESET_INTERACTIVE_RESUME_STATE';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_SKY_PROPS = 'SET_SKY_PROPS';
export const SET_SIDE_NAV_BAR_VISIBILITY = 'SET_SIDE_NAV_BAR_VISIBILITY';
export const SET_SCREEN_PROPS = 'SET_SCREEN_PROPS';
export const SET_PWA_PROPS = 'SET_PWA_PROPS';
export const SET_SHARE_MODEL_VISIBILITY = 'SET_SHARE_MODEL_VISIBILITY';
