import React from 'react';
import { classnames } from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './sun.module.scss';

interface ISunComponent extends IDefaultResponsiveSupport {
  isNighMode: boolean;
}
export default class SunComponent extends React.PureComponent<ISunComponent> {
  render() {
    const { responsiveMode, isNighMode } = this.props;
    return (
      <div
        className={classnames(
          'fullScreenWindow',
          styles.sunContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div
          className={classnames(styles.sun, styles.day, {
            [styles.night]: isNighMode,
          })}
        >
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    );
  }
}
