import React from 'react';
import { Directions } from '../../../common/constant/interactive.resume.constant';
import {
  classnames,
  isTouchDevice,
} from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../common/interfaces/common.interface';
import AnimationService from '../../../common/services/animatin.service';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './mobile-navigation.module.scss';

interface IMobileNavigationComponent extends IDefaultResponsiveSupport {
  scrollPostion: number;
}

interface IMobileNavigationComponentState {
  isInfoVisible: boolean;
}
export default class MobileNavigationComponent extends React.Component<
  IMobileNavigationComponent,
  IMobileNavigationComponentState
> {
  constructor(props: IMobileNavigationComponent) {
    super(props);
    this.state = {
      isInfoVisible: props.scrollPostion > 100 ? false : true,
    };
  }

  private onTouchStartRight = () => {
    AnimationService.keyDown(Directions.ArrowRight);
  };

  private onTouchEndRight = () => {
    AnimationService.keyUp(Directions.ArrowRight);
  };

  private onTouchStartLeft = () => {
    AnimationService.keyDown(Directions.ArrowLeft);
  };

  private onTouchEndLeft = () => {
    AnimationService.keyUp(Directions.ArrowLeft);
  };

  private onTouchStartTop = () => {
    AnimationService.keyDown(Directions.ArrowUp);
  };

  private onTouchEndTop = () => {
    AnimationService.keyUp(Directions.ArrowUp);
  };

  shouldComponentUpdate(
    nextProps: IMobileNavigationComponent,
    nextState: IMobileNavigationComponentState
  ) {
    const { scrollPostion, responsiveMode } = this.props;

    if (
      responsiveMode !== nextProps.responsiveMode ||
      this.state.isInfoVisible !== nextState.isInfoVisible
    ) {
      return true;
    }

    if (
      this.state.isInfoVisible &&
      scrollPostion !== nextProps.scrollPostion &&
      scrollPostion > 100
    ) {
      this.setState({ isInfoVisible: false });
      return true;
    }

    return false;
  }

  private get leftInfo() {
    if (!this.state.isInfoVisible) return null;
    return (
      <div>
        {isTouchDevice ? (
          <p>Click here to move "Left"</p>
        ) : (
          <p>Use "(&#8592;) Left" arrow to move "Left"</p>
        )}
      </div>
    );
  }

  private get middleInfo() {
    if (!this.state.isInfoVisible) return null;
    return (
      <div>
        {isTouchDevice ? (
          <p>Click here to "Jump"</p>
        ) : (
          <p>Use "(&#8593;) Up" arrow to "Jump"</p>
        )}
      </div>
    );
  }

  private get rightInfo() {
    if (!this.state.isInfoVisible) return null;
    return (
      <div>
        {isTouchDevice ? (
          <p>Click here to move "Right"</p>
        ) : (
          <p>Use "(&#8594;) Right" arrow to move "Right"</p>
        )}
      </div>
    );
  }

  render() {
    const { responsiveMode } = this.props;
    return (
      <div
        className={classnames(
          'fullScreenWindow',
          styles.mobileNavigationContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div className={classnames('flex-row', styles.navigations)}>
          <div
            className={classnames('size-auto', styles.left)}
            onTouchStart={isTouchDevice ? this.onTouchStartLeft : undefined}
            onTouchEnd={isTouchDevice ? this.onTouchEndLeft : undefined}
          >
            {this.leftInfo}
          </div>
          <div
            className={classnames('size-auto', styles.middle)}
            onTouchStart={isTouchDevice ? this.onTouchStartTop : undefined}
            onTouchEnd={isTouchDevice ? this.onTouchEndTop : undefined}
          >
            {this.middleInfo}
          </div>
          <div
            className={classnames('size-auto', styles.right)}
            onTouchStart={isTouchDevice ? this.onTouchStartRight : undefined}
            onTouchEnd={isTouchDevice ? this.onTouchEndRight : undefined}
          >
            {this.rightInfo}
          </div>
        </div>
      </div>
    );
  }
}
