import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { ResponsiveMode } from '../interfaces/common.interface';

interface IDictionaryMap {
  [className: string]: boolean;
}
interface ISerializableObject {
  toString?: () => string;
}

type ICssInput =
  | string
  | ISerializableObject
  | IDictionaryMap
  | null
  | undefined
  | boolean;

export const classnames = (...args: ICssInput[]) => {
  const classes = [];

  for (const arg of args) {
    if (arg) {
      if (typeof arg === 'string') {
        classes.push(arg);
      } else if (
        arg.hasOwnProperty('toString') &&
        typeof arg.toString === 'function'
      ) {
        classes.push(arg.toString());
      } else {
        for (const key in arg as any) {
          if ((arg as any)[key]) {
            classes.push(key);
          }
        }
      }
    }
  }

  return classes.join(' ');
};

export const isNullOrUndefined = (value: any) => {
  return value === undefined || value === null;
};

export const addRule = (function (style) {
  style.setAttribute('name', 'customStyle');
  const sheet = document.body.appendChild(style);
  const classes: {
    [key: string]: string | number | Object;
  } = {};
  return function (
    selector: string,
    css: {
      [key: string]: string | number;
    }
  ) {
    classes[selector] = css;
    sheet.innerHTML = '';
    Object.entries(classes).forEach((entry: any) => {
      var propText =
        typeof css === 'string'
          ? css
          : Object.keys(entry[1])
              .map(function (p) {
                return (
                  p +
                  ':' +
                  (p === 'content' ? "'" + entry[1][p] + "'" : entry[1][p])
                );
              })
              .join(';');
      sheet.innerHTML += `${entry[0]}{${propText}}`;
    });
  };
})(document.createElement('style'));

export const generateIntRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isTouchDevice =
  'ontouchstart' in window ||
  navigator?.maxTouchPoints > 0 ||
  (navigator as any)?.msMaxTouchPoints > 0;

export const getWindowSize = () => {
  const width: number =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const height: number =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return { width, height };
};

export const getResponsiveMode = (width: number): ResponsiveMode => {
  if (width < 600) {
    return 'mobile';
  } else if (width < 1024) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

export const LinkMemo = memo(NavLink, () => true);

export const mediaOrientationQueryList: MediaQueryList = window.matchMedia("(orientation: portrait)");
export const isFullScreen = () => document?.fullscreenElement || (document as any).webkitCurrentFullScreenElement;