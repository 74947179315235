import React from 'react';
import { birdAvatar } from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './birds.module.scss';

interface IBirdsComponent extends IDefaultResponsiveSupport {}
export default class BirdsComponent extends React.PureComponent<IBirdsComponent> {
  render() {
    const { responsiveMode } = this.props;
    return (
      <div
        className={classnames(
          'fullScreenWindow',
          styles.birdsContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div
          style={{
            backgroundImage: birdAvatar,
          }}
          className={classnames(styles.bird, styles.bird_1)}
        ></div>
        <div
          style={{
            backgroundImage: birdAvatar,
          }}
          className={classnames(styles.bird, styles.bird_2)}
        ></div>
        <div
          style={{
            backgroundImage: birdAvatar,
          }}
          className={classnames(styles.bird, styles.bird_3)}
        ></div>
        <div
          style={{
            backgroundImage: birdAvatar,
          }}
          className={classnames(styles.bird, styles.bird_4)}
        ></div>
      </div>
    );
  }
}
