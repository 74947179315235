import React, { Dispatch, Reducer, useReducer } from 'react';
import { LOCAL_STORAGE_KEY } from '../common/interfaces/common.interface';

interface IActions<A> {
  [key: string]: (d: Dispatch<A>) => (...arg: any) => void;
}


const createDataContext = <S, A>(
  reducer: Reducer<S, A>,
  actions: IActions<A>,
  initialState: S
) => {
  const Context = React.createContext<
    | {
        state?: S;
      }
    | IActions<A>
  >({});

  const reducerMiddleWare = (state: S, action: A) => {
    const newState: any = reducer(state, action);
    if (LOCAL_STORAGE_KEY[(action as any).type]) {
      localStorage.setItem(
        (action as any).type,
        JSON.stringify(newState[LOCAL_STORAGE_KEY[(action as any).type]])
      );
    }
    return newState;
  };

  let restoreInitialState = { ...initialState };

  Object.keys(LOCAL_STORAGE_KEY).forEach((key) => {
    const localState = localStorage.getItem(key);
    if (localState) {
      restoreInitialState = {
        ...restoreInitialState,
        [LOCAL_STORAGE_KEY[key]]: JSON.parse(localState),
      };
    }
  });

  const Provider = ({
    children,
  }: {
    [key: string]: JSX.Element;
  }): JSX.Element => {
    const [state, dispatch] = useReducer(
      reducerMiddleWare,
      restoreInitialState
    );

    const boundActions: {
      [key: string]: (payload?: any) => void;
    } = {};
    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};

export default createDataContext;
