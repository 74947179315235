import React from 'react';
import {
  classnames,
  generateIntRandomNumber,
} from '../../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IPersonProps,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './firecrackers.module.scss';

interface IFirecrackers extends IDefaultResponsiveSupport {
  left: number;
  scrollPostion: number;
  personProps: IPersonProps;
}

interface IFirecrackersState {
  runAnimation: boolean;
  firecracker: JSX.Element | null;
}

export default class Firecrackers extends React.Component<
  IFirecrackers,
  IFirecrackersState
> {
  private eleRef: React.RefObject<any>;
  private count = 10;

  constructor(props: IFirecrackers) {
    super(props);
    this.eleRef = React.createRef();
    this.state = {
      runAnimation: false,
      firecracker: null,
    };
  }

  shouldComponentUpdate(
    nextProps: IFirecrackers,
    nextState: IFirecrackersState
  ) {
    const { scrollPostion, responsiveMode } = this.props;
    if (scrollPostion !== nextProps.scrollPostion && !this.state.runAnimation) {
      this.runAnimation(nextProps);
    }

    if (
      responsiveMode !== nextProps.responsiveMode ||
      this.state.runAnimation !== nextState.runAnimation ||
      this.state.firecracker !== nextState.firecracker
    ) {
      return true;
    }
    return false;
  }

  private runAnimation({
    personProps: { getBoundingClientRect: getPersonBoundingClientRect },
  }: IFirecrackers) {
    const bulding = this.eleRef?.current?.getBoundingClientRect();

    if (getPersonBoundingClientRect?.right > bulding.left) {
      this.setState({ runAnimation: true });
      this.startFireCrackers();
    }
  }

  render() {
    const { responsiveMode, left } = this.props;
    return (
      <div
        ref={this.eleRef}
        style={{
          left,
        }}
        className={classnames(
          styles.firecrackersContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        {this.state.firecracker}
      </div>
    );
  }

  private getFirecracker = (key: string | number) => {
    const { left: leftR, top: topR } = this.range;
    const left = generateIntRandomNumber(-1 * leftR, leftR) + 'px';
    const top = generateIntRandomNumber(1, topR) + 'px';
    return (
      <div
        key={key}
        style={{ left, top }}
        className={classnames(styles.firecrackers)}
      >
        <div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    );
  };

  private startFireCrackers = () => {
    if (this.count > 0) {
      this.setState({ firecracker: this.getFirecracker(this.count) }, () => {
        this.count--;
        setTimeout(this.startFireCrackers, 1000);
      });
    } else {
      this.setState({ firecracker: null });
    }
  };

  private get range() {
    const { responsiveMode } = this.props;
    switch (responsiveMode) {
      case 'mobile':
        return { left: 100, top: 300 };
      case 'tablet':
        return { left: 300, top: 100 };
      default:
        return { left: 500, top: 200 };
    }
  }
}
