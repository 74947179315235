import React from 'react';
import {
  classnames,
  LinkMemo,
} from '../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IResumeData,
  PAGE_ROUTES,
  SCREEN_ORIENTATION,
} from '../../common/interfaces/common.interface';
import ResponsiveModeService from '../../common/services/responsive-mode.service';
import {
  Context as GlobalContext,
  GlobalContextType,
} from '../../context/global.context';
import AbovePlatformComponent from './above-platform/above-platform.component';
import BirdsComponent from './birds/birds.component';
import PersonOnBoatComponent from './person-on-boat/person-on-boat.component';
import CloudComponent from './cloud/cloud.component';
import styles from './interactive-resume-page.module.scss';
import MobileNavigationComponent from './mobile-navigation/mobile-navigation.component';
import MountainsAndCityComponent from './mountains-and-city/mountains-and-city.component';
import PersonComponent from './person/person.component';
import PlatformComponent from './plat-form/platform.component';
import SkyComponent from './sky/sky.component';
import SunComponent from './sun/sun.component';
import PersonOnTrainComponent from './person-on-train/person-on-train.component';
import PersonInCarComponent from './person-in-car/person-in-car.component';
import { Title } from '../../components/title/title.component';
import {
  HomepageSVG,
  Interactive_Resume_Page_Title,
  ShareSVG,
} from '../../common/constant/resume-data.constant';
import SideNavMenuIconComponent from '../../components/side-nav-menu-icon/side-nav-menu-icon.component';
import MessageComponent from '../../components/message/message.component';
import LayoutService from '../../common/services/layout.service';

interface IInteractiveResume extends IDefaultResponsiveSupport {}
export default class InteractiveResumePageComponent extends React.PureComponent<IInteractiveResume> {
  static contextType = GlobalContext;

  componentWillUnmount() {
    const { resetInteractiveResumeState }: GlobalContextType = this.context;
    resetInteractiveResumeState && resetInteractiveResumeState();
  }

  componentDidMount() {
    const { setShouldMoveScrolls }: GlobalContextType = this.context;
    setShouldMoveScrolls && setShouldMoveScrolls(true);
  }

  private get topRightLinks() {
    return (
      <div className={classnames(styles.topRightLinks)}>
        <LinkMemo
          className={classnames(styles.homeIconLink)}
          to={PAGE_ROUTES.HOME}
          title={'Home'}
        >
          <HomepageSVG
            className={classnames(styles.SVGIcon)}
          />
        </LinkMemo>
        {this.shareButtonIcon}
      </div>
    );
  }

  private get shareButtonIcon() {
    const {
      setShareModelVisibility,
    }: GlobalContextType = this.context;
    return (
      <button onClick={setShareModelVisibility.bind(this,true)} className={classnames(styles.iconButton, styles.shareButtonIcon)}>
        <ShareSVG title={'Share'} className={classnames(styles.SVGIcon)} />
      </button>
    );
  }


  render() {
    const { responsiveMode } = this.props;
    const {
      state: {
        scrollPostions,
        layout,
        personProps,
        personOnBoatProps,
        personOnTrainProps,
        personInCarProps,
        resumeData,
        sky,
        shouldMoveScrolls,
        isSideBarVisible,
        screen,
      },
      setShouldMoveScrolls,
      setPersonProps,
      setPersonOnBoatProps,
      setPersonOnTrainProps,
      setPersonInCarProps,
      setSkyProps,
      setSideNavBarVisibility,
    }: GlobalContextType = this.context;
    return (
      <div
        className={classnames(
          styles.interactiveResumeContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <Title>{Interactive_Resume_Page_Title}</Title>
        <SideNavMenuIconComponent
          isSideBarVisible={isSideBarVisible}
          setSideNavBarVisibility={setSideNavBarVisibility}
          responsiveMode={responsiveMode}
          className={styles.sideBarMenuIcon}
        />
        {this.topRightLinks}
        <MessageComponent
          onSuccess={LayoutService.rotateScreen.bind(
            LayoutService,
            SCREEN_ORIENTATION.LANDSCAPE
          )}
          responsiveMode={responsiveMode}
          isOpen={screen.orientation === SCREEN_ORIENTATION.PORTRAIT}
          msg={'Launch webside in Landscape Mode for better experience.'}
        />
        <div className={classnames('flex-col', styles.flexContainer)}>
          <div className={classnames('size-9', styles.topBox)}>
            <SkyComponent
              isStarVisible={sky.isStarVisible}
              backgroundColor={sky.backgroundColor}
              responsiveMode={responsiveMode}
            />
            <SunComponent
              isNighMode={sky.isStarVisible}
              responsiveMode={responsiveMode}
            />
            <CloudComponent
              scrollPostion={scrollPostions.cloud}
              responsiveMode={responsiveMode}
              cloudType={sky.cloudType}
            />
            <BirdsComponent responsiveMode={responsiveMode} />
            <MountainsAndCityComponent
              scrollPostion={scrollPostions.mountainAndCity}
              responsiveMode={responsiveMode}
              employmentHistorysCount={
                (resumeData as IResumeData).employmentHistory.length
              }
            />
            <AbovePlatformComponent
              scrollPostion={scrollPostions.platform}
              responsiveMode={responsiveMode}
              personProps={personProps}
              resumeData={resumeData as IResumeData}
              setShouldMoveScrolls={setShouldMoveScrolls}
              setPersonProps={setPersonProps}
              layout={layout}
              setPersonOnBoatProps={setPersonOnBoatProps}
              setPersonOnTrainProps={setPersonOnTrainProps}
              setPersonInCarProps={setPersonInCarProps}
              personOnBoatProps={personOnBoatProps}
              personOnTrainProps={personOnTrainProps}
              personInCarProps={personInCarProps}
              setSkyProps={setSkyProps}
              shouldMoveScrolls={shouldMoveScrolls}
              aboutMe={resumeData?.aboutMe!}
            />
            <PersonComponent
              layout={layout}
              personProps={personProps}
              responsiveMode={responsiveMode}
              setPersonProps={setPersonProps}
            />
            <PersonOnBoatComponent
              layout={layout}
              personProps={personProps}
              personOnBoatProps={personOnBoatProps}
              responsiveMode={responsiveMode}
              setPersonOnBoatProps={setPersonOnBoatProps}
            />
            <PersonOnTrainComponent
              layout={layout}
              personProps={personProps}
              personOnTrainProps={personOnTrainProps}
              responsiveMode={responsiveMode}
              setPersonOnTrainProps={setPersonOnTrainProps}
            />
            <PersonInCarComponent
              layout={layout}
              personProps={personProps}
              personInCarProps={personInCarProps}
              responsiveMode={responsiveMode}
              setPersonInCarProps={setPersonInCarProps}
            />
          </div>
          <div className={classnames('size-3', styles.bottomBox)}>
            <PlatformComponent
              scrollPostion={scrollPostions.platform}
              responsiveMode={responsiveMode}
            />
            <MobileNavigationComponent
              scrollPostion={scrollPostions.platform}
              responsiveMode={responsiveMode}
            />
          </div>
        </div>
      </div>
    );
  }
}
