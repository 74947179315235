import React from 'react';
import { classnames } from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport, IProfessionalSkills, ISkills } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import IntersectionObserverComponent from '../../../components/intersection-observer.component';
import styles from './professional-skills.module.scss';
import SkillComponent from './skill/skill.component';

interface IProfessionalSkillsComponent extends IDefaultResponsiveSupport {
    professionalSkills?: IProfessionalSkills[]
}
export default class ProfessionalSkillsComponent extends React.PureComponent<IProfessionalSkillsComponent> {
    render() {
        const { responsiveMode, professionalSkills } = this.props;
        return (
            <div className={classnames(styles.professionalSkillsContainer, ResponsiveModeService.responsiveModeClass(styles, responsiveMode))}>
                <h1 className={classnames('resumeHeading1')}>Professional Skills</h1>
                {professionalSkills?.map((item: IProfessionalSkills, i) => {
                    return this.getSkills(item.title, item.skills, i)
                })}
            </div>
        );
    }

    private getSkills(heading: string, list: ISkills[] | undefined, index: number): JSX.Element {
        const { responsiveMode } = this.props;
        return (
            <div key={index} className={styles.skillsContainer}>
                <h2 className={classnames('resumeHeading2')}>{heading}</h2>
                <div className={styles.skillContainer}>
                    {list?.map((data, i) => {
                        return (
                            <IntersectionObserverComponent key={i} animationDelay={0.1 * i} className={'fadeInAnimation'} animationName={'fadeInFromRight'} observerOneTime={true}>
                                <SkillComponent tooltip={data.tooltip} label={data.label} position={data?.position} responsiveMode={responsiveMode} />
                            </IntersectionObserverComponent>
                        );
                    })}
                </div>
            </div>
        );
    }
}