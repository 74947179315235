import React from 'react';

interface IIntersectionObserverComponent {
  className?: string;
  animationName?: string;
  animationDelay?: number;
  observerOneTime?: boolean;
  threshold?: number;
  callback?: (isIntersecting: boolean) => void;
  display?: 'block' | 'inline-block';
}
interface IIntersectionObserverComponentState {
  isIntersecting: boolean;
}

export default class IntersectionObserverComponent extends React.PureComponent<
  IIntersectionObserverComponent,
  IIntersectionObserverComponentState
> {
  private eleRef: React.RefObject<any>;
  public static defaultProps = {
    observerOneTime: false,
    animationDelay: 0,
    threshold: 0.5,
    display: 'inline-block',
  };

  constructor(props: any) {
    super(props);
    this.eleRef = React.createRef();
    this.state = {
      isIntersecting: false,
    };
  }

  componentDidMount() {
    const { callback, threshold } = this.props;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold,
    };
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.target === this.eleRef.current) {
          this.setState({ isIntersecting: entry.isIntersecting });
          if (callback) {
            callback(entry.isIntersecting);
          }
          if (this.props.observerOneTime && entry.isIntersecting) {
            observer.unobserve(entry.target);
          }
        }
      });
    }, options);
    observer.observe(this.eleRef.current);
  }

  render() {
    const { className, animationName, animationDelay, display } = this.props;
    return (
      <span
        ref={this.eleRef}
        style={{
          animationName:
            this.state.isIntersecting && animationName ? animationName : 'none',
          animationDelay:
            animationName && animationDelay ? `${animationDelay}s` : '0s',
          display,
        }}
        className={className}
      >
        {this.props.children}
      </span>
    );
  }
}
