import React from 'react';
import {
  BLOCKS_NAME,
  Directions,
} from '../../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IPersonProps,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import {
  ISetPersonProps,
  ISetShouldMoveScrolls,
} from '../../../../context/action';
import styles from './block.module.scss';

export enum BlockType {
  Normal = 1,
  Sea = 2,
  Hidden = 3,
}
interface IBlockComponent extends IDefaultResponsiveSupport {
  id: number;
  left: number;
  classes: string[];
  personProps: IPersonProps;
  setShouldMoveScrolls: ISetShouldMoveScrolls;
  setPersonProps: ISetPersonProps;
  scrollPostion: number;
  type: BlockType;
  name: BLOCKS_NAME;
  shouldMoveScrolls: boolean;
}
export default class BlockComponent extends React.Component<IBlockComponent> {
  public static defaultProps: Partial<IBlockComponent> = {
    left: 10,
  };
  private blocksRef: React.RefObject<any>;

  constructor(props: IBlockComponent) {
    super(props);
    this.blocksRef = React.createRef();
  }

  shouldComponentUpdate(nextProps: IBlockComponent) {
    const {
      scrollPostion,
      personProps: { direction, nextPlatform, currentPlatform, getBoundingClientRect },
      responsiveMode,
    } = this.props;

    if (
      getBoundingClientRect.left !== nextProps.personProps.getBoundingClientRect.left ||
      getBoundingClientRect.right !== nextProps.personProps.getBoundingClientRect.right ||
      getBoundingClientRect.width !== nextProps.personProps.getBoundingClientRect.width
    ) {
      this.fixLayout(nextProps);
    }

    if (responsiveMode !== nextProps.responsiveMode) {
      return true;
    }

    if (
      scrollPostion !== nextProps.scrollPostion ||
      direction !== nextProps.personProps.direction ||
      nextPlatform !== nextProps.personProps.nextPlatform ||
      currentPlatform !== nextProps.personProps.currentPlatform
    ) {
      this.checkForBlocks(nextProps);
    }

    return false;
  }

  private fixLayout({
    personProps: {
      nextPlatform,
      currentPlatform,
      getBoundingClientRect: getPersonBoundingClientRect,
    },
    setShouldMoveScrolls,
    setPersonProps,
    name,
  }: IBlockComponent) {
    const block = this.blocksRef?.current?.getBoundingClientRect();
    const personCenter =
      getPersonBoundingClientRect?.left +
      getPersonBoundingClientRect?.width / 2;

    if (
      currentPlatform !== this.blocksRef &&
      personCenter > block.left &&
      personCenter < block.right
    ) {
      const updatedPlatform =
        name === BLOCKS_NAME.FINISH_BLOCK ? null : this.blocksRef;
      setPersonProps({
        currentPlatform: updatedPlatform,
        nextPlatform: updatedPlatform,
      });
      setShouldMoveScrolls(true);
    } else if (
      (currentPlatform === this.blocksRef || nextPlatform === this.blocksRef) &&
      (personCenter < block.left || personCenter > block.right)
    ) {
      setPersonProps({ currentPlatform: null, nextPlatform: null });
      setShouldMoveScrolls(true);
    }
  }

  private checkForBlocks({
    personProps: {
      direction,
      nextPlatform,
      currentPlatform,
      getBoundingClientRect: getPersonBoundingClientRect,
    },
    shouldMoveScrolls,
    setShouldMoveScrolls,
    setPersonProps,
    type,
  }: IBlockComponent) {
    const block = this.blocksRef?.current?.getBoundingClientRect();
    const {
      paddingLeftBottom,
      paddingRightBottom,
      paddingLeftTop,
      paddingRightTop,
    } = this.padding;

    if (
      nextPlatform === null &&
      ((direction === Directions.ArrowRight &&
        getPersonBoundingClientRect?.right - paddingLeftBottom > block.left &&
        getPersonBoundingClientRect?.left < block.left) ||
        (direction === Directions.ArrowLeft &&
          getPersonBoundingClientRect?.left + paddingRightBottom <
            block.right &&
          getPersonBoundingClientRect?.right > block.right))
    ) {
      // Block the pass without jump
      setPersonProps({ nextPlatform: this.blocksRef });
      setShouldMoveScrolls(false);
      return;
    } else if (
      (nextPlatform === this.blocksRef &&
        currentPlatform !== this.blocksRef &&
        ((direction === Directions.ArrowLeft &&
          getPersonBoundingClientRect?.left < block.left) ||
          (direction === Directions.ArrowRight &&
            getPersonBoundingClientRect?.right > block.right))) ||
      (currentPlatform === this.blocksRef &&
        ((direction === Directions.ArrowRight &&
          getPersonBoundingClientRect?.left + paddingRightTop > block.right) ||
          (direction === Directions.ArrowLeft &&
            getPersonBoundingClientRect?.right - paddingLeftTop < block.left)))
    ) {
      // Reverse the direction without jump + Auto jump to main platform
      setShouldMoveScrolls(true);
      setPersonProps({ nextPlatform: null, currentPlatform: null });
      return;
    } else if (currentPlatform === this.blocksRef) {
      // allow to move on platform
      setShouldMoveScrolls(true);
      return;
    }

    if (
      shouldMoveScrolls &&
      type === BlockType.Hidden &&
      direction === Directions.ArrowRight &&
      getPersonBoundingClientRect?.right > block.left
    ) {
      setShouldMoveScrolls(false);
    } else if (
      !shouldMoveScrolls &&
      type === BlockType.Hidden &&
      direction === Directions.ArrowLeft &&
      getPersonBoundingClientRect?.right > block.left
    ) {
      setShouldMoveScrolls(true);
    }
  }

  private get padding() {
    const { responsiveMode } = this.props;
    switch (responsiveMode) {
      case 'mobile':
        return {
          paddingLeftBottom: 10,
          paddingLeftTop: 50,
          paddingRightBottom: 20,
          paddingRightTop: 50,
        };
      case 'tablet':
        return {
          paddingLeftBottom: 5,
          paddingLeftTop: 50,
          paddingRightBottom: 5,
          paddingRightTop: 45,
        };
      default:
        return {
          paddingLeftBottom: 20,
          paddingLeftTop: 60,
          paddingRightBottom: 20,
          paddingRightTop: 60,
        };
    }
  }

  render() {
    const { responsiveMode, left, name } = this.props;
    return (
      <div
        style={{
          left,
        }}
        className={classnames(
          styles.blockContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
        ref={this.blocksRef}
        data-name={name}
      >
        {this.block}
      </div>
    );
  }

  private get block() {
    const { type } = this.props;
    return (
      <div className={classnames(...this.classList)}>
        {type === BlockType.Sea ? (
          <>
            <div className={styles.seaBlock}></div>
            <div className={styles.seaBlock}></div>
          </>
        ) : null}
      </div>
    );
  }

  private get classList() {
    const { classes, type } = this.props;
    switch (type) {
      case BlockType.Normal:
        return [styles.blockType_Normal, ...classes];
      case BlockType.Sea:
        return [styles.blockType_Sea, ...classes];
      case BlockType.Hidden:
        return [styles.blockType_Normal, styles.hidden];
      default:
        return [styles.blockType_Normal];
    }
  }
}
