import React from 'react';
import { Directions, VEHICLE_TYPE } from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IPersonOnObjectProps,
  IDefaultResponsiveSupport,
  IPersonProps,
  Layout,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import { ISetPersonOnTrainProps } from '../../../context/action';
import TrainComponent from '../above-platform/train/train.component';
import styles from './person-on-train.module.scss';

interface IPersonOnTrainComponent extends IDefaultResponsiveSupport {
  layout: Layout;
  personProps: IPersonProps;
  personOnTrainProps: IPersonOnObjectProps;
  setPersonOnTrainProps: ISetPersonOnTrainProps;
}
export default class PersonOnTrainComponent extends React.Component<IPersonOnTrainComponent> {
  private fixPosition({
    layout: { width: layoutWidth },
    personOnTrainProps: { ref },
    setPersonOnTrainProps,
  }: IPersonOnTrainComponent) {
    if (ref && ref.current) {
      ref.current.style.left = `${layoutWidth / 2 - ref.current.offsetWidth / 2}px`;
      const { width, height, left, right } =
      ref?.current?.getBoundingClientRect();
      setPersonOnTrainProps({
        getBoundingClientRect: {
          width,
          height,
          left,
          right,
        },
      });
    }
  }

  shouldComponentUpdate(nextProps: IPersonOnTrainComponent) {
    const { responsiveMode, personProps, layout } = this.props;
    if (
      layout.width !== nextProps.layout.width ||
      layout.height !== nextProps.layout.height
    ) {
      this.fixPosition(nextProps);
      return false;
    }

    if (
      personProps.direction !== nextProps.personProps.direction ||
      personProps.isRunning !== nextProps.personProps.isRunning ||
      responsiveMode !== nextProps.responsiveMode ||
      personProps.isVisible !== nextProps.personProps.isVisible
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.fixPosition(this.props);
  }

  render() {
    const { responsiveMode, personProps, personOnTrainProps } = this.props;

    return (
      <div
        ref={personOnTrainProps.ref}
        className={classnames(
          styles.personOnTrainContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          {
            [styles.hidden]: !personOnTrainProps.isVisible,
          }
        )}
      >
        <TrainComponent
          responsiveMode={responsiveMode}
          isRotateBack={personProps.direction === Directions.ArrowLeft}
          isVisible={true}
          isRunning={personProps.isRunning}
          type={VEHICLE_TYPE.FULL}
        />
      </div>
    );
  }
}
