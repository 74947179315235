import React from 'react';
import {
  craneType1Avatar,
  EMPLOYEMENTHISTORY_CRANE_LEFT,
  EMPLOYEMENTHISTORY_PADDING_LEFT,
} from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './mountains-and-city.module.scss';

interface IMountainsComponent extends IDefaultResponsiveSupport {
  scrollPostion: number;
  employmentHistorysCount: number;
}
export default class MountainsAndCityComponent extends React.Component<IMountainsComponent> {
  private eleRef: React.RefObject<any>;

  constructor(props: IMountainsComponent) {
    super(props);
    this.eleRef = React.createRef();
  }

  shouldComponentUpdate(nextProps: IMountainsComponent) {
    const { scrollPostion, responsiveMode } = this.props;
    if (responsiveMode !== nextProps.responsiveMode) {
      return true;
    }
    if (scrollPostion !== nextProps.scrollPostion) {
      this.eleRef.current.scrollTo(nextProps.scrollPostion, 0);
    }
    return false;
  }

  render() {
    const { responsiveMode } = this.props;
    return (
      <div
        ref={this.eleRef}
        className={classnames(
          'fullScreenWindow',
          styles.mountainsAndCityContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div className={classnames('windowReel', styles.backgroundImages)}>
          {this.cranesForEmploymentHistory}
        </div>
      </div>
    );
  }


  private getCrane(key: number, left: number) {
    return (
      <div
        key={key}
        style={{ backgroundImage: craneType1Avatar, left }}
        className={classnames(styles.crane)}
      ></div>
    );
  }

  private get cranesForEmploymentHistory() {
    const { employmentHistorysCount } = this.props;
    return [...Array(employmentHistorysCount-1)].map((_item, i) => {
      return this.getCrane(
        i,
        EMPLOYEMENTHISTORY_CRANE_LEFT +
          EMPLOYEMENTHISTORY_PADDING_LEFT * (i + 1)
      );
    });
  }
}
