import React from 'react';
import {
  mapAvatar,
} from '../../../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../../common/services/responsive-mode.service';
import styles from './globe.module.scss';

interface IGlobeComponent extends IDefaultResponsiveSupport {
  isAnimated: boolean;
}
export default class GlobeComponent extends React.PureComponent<IGlobeComponent> {
  render() {
    const { responsiveMode, isAnimated } = this.props;
    return (
      <div
        className={classnames(
          'flex-col',
          styles.globeContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          {
            [styles.moveDown]: isAnimated,
          }
        )}
      >
        <div className={classnames('size-auto', styles.chain)}>
          <p>Live & Work</p>
        </div>
        <div
          style={{
            backgroundImage: mapAvatar,
          }}
          className={classnames(styles.globe, {
            [styles.globeAnim]: isAnimated,
          })}
        ></div>
      </div>
    );
  }
}
