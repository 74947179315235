import React from 'react';
import { classnames } from '../../common/general-utilities/general-utilities';
import styles from './pill.module.scss';


export enum PillColors {
    grey = 'grey',
    red = 'red',
    orange = 'orange',
    green = 'green',
    black = 'black',
    blue = 'blue',
    cyan = 'cyan',
    none = 'none',
}

export enum PillSizes {
    normal = 'normal',
    small = 'small',
}

export enum PillShapes {
    rectangle = 'rectangle',
    rounded = 'rounded',
}

export enum PillTooltipDirection {
    top = '',
    bottom = 'bottom',
    right = 'right',
    left = 'left',
}

const PillColorClassNames: {
    [key: string]: string
} = {
    grey: styles.grey,
    red: styles.red,
    orange: styles.orange,
    green: styles.green,
    black: styles.black,
    blue: styles.blue,
    cyan: styles.cyan,
    none: ' ',
};

const PillSizeClassNames: {
    [key: string]: string
} = {
    normal: styles.normal,
    small: styles.small,
};

const PillShapeClassNames: {
    [key: string]: string
} = {
    rectangle: styles.rectangle,
    rounded: styles.rounded,
};

const PillTooltipDirectionClassNames: {
    [key: string]: string
} = {
    top: ' ',
    bottom: 'bottom',
    right: 'right',
    left: 'left',
};

export interface IPillProps {
    color?: PillColors;
    size?: PillSizes;
    shape?: PillShapes;
    classNames?: string;
    tooltip?: string;
    tooltipDirection?: PillTooltipDirection;
}

export default class PillComponent extends React.PureComponent<IPillProps> {
    public static defaultProps = {
        color: PillColors.grey,
        shape: PillShapes.rounded,
        size: PillSizes.normal,
        tooltipDirection: PillTooltipDirection.top
    };
    render() {
        return (
            <span className={this.classeNames}>
                {this.props.children}
                {this.props.tooltip ? (<span className={classnames('tooltiptext', this.tooltipDirectionClassName)}>{this.props.tooltip}</span>) : null}
            </span>
        );
    }

    private get classeNames() {
        const classes = [
            styles.root, this.colorClassName, this.sizeClassName, this.shapeClassName, this.props.classNames
        ];
        if (this.props.tooltip) {
            classes.push('tooltip')
        }
        return classes.join(' ');
    }

    private get colorClassName(): string {
        return PillColorClassNames[this.props.color as string] || PillColorClassNames[PillComponent.defaultProps.color as string];
    }

    private get sizeClassName(): string {
        return PillSizeClassNames[this.props.size as string] || PillSizeClassNames[PillComponent.defaultProps.size];
    }

    private get shapeClassName(): string {
        return PillShapeClassNames[this.props.shape as string] || PillShapeClassNames[PillComponent.defaultProps.shape];
    }

    private get tooltipDirectionClassName(): string {
        return PillTooltipDirectionClassNames[this.props.tooltipDirection as string] || PillTooltipDirectionClassNames[PillComponent.defaultProps.tooltipDirection];
    }
}