import personImg from '../../assets/img/person.png';
import birdImg from '../../assets/img/bird.png';
import buildingType1Img from '../../assets/img/building_1.png';
import graduatedImg from '../../assets/img/graduated.png';
import groundWaterImg from '../../assets/img/groundWater.png';
import boatImg from '../../assets/img/boat.png';
import trainGroundImg from '../../assets/img/trainGround.png';
import soilImg from '../../assets/img/soil.png';
import semiRoadImg from '../../assets/img/semiRoad.png';
import trainImg from '../../assets/img/train.png';
import grayGroundImg from '../../assets/img/grayGround.png';
import carImg from '../../assets/img/car.png';
import tierImg from '../../assets/img/tier.png';
import legsImg from '../../assets/img/legs.png';
import mapImg from '../../assets/img/map.jpg';
import craneType1Img from '../../assets/img/crane_type1.png';
import cityImg from '../../assets/img/city.png';
import city2Img from '../../assets/img/city2.png';
import city3Img from '../../assets/img/city3.png';
import fenceImg from '../../assets/img/fence.png';
import mountainImg from '../../assets/img/mountain.png';
import profilePhoto from '../../assets/img/profilePhoto.jpg';
import railwaySignImg from '../../assets/img/railwaySign.png';
import streetLightImg from '../../assets/img/streetLight.png';
import windowImg from '../../assets/img/window.jpg';
import languageIconImg from '../../assets/img/icons.png';



import React from 'react';
import { BlockType } from '../../pages/interactive-resume/above-platform/block/block.component';
import blockStyle from '../../pages/interactive-resume/above-platform/block/block.module.scss';
import commonStyle from '../../pages/interactive-resume/interactive-resume-page.module.scss';

export const PersonAvatar = `url(${personImg})`;
export const birdAvatar = `url(${birdImg})`;
export const groundWaterAvatar = `url(${groundWaterImg})`;
export const boatAvatar = `url(${boatImg})`;
export const trainGroundAvatar = `url(${trainGroundImg})`;
export const buildingType1Avatar = `url(${buildingType1Img})`;
export const graduatedAvatar = `url(${graduatedImg})`;
export const trainAvatar = `url(${trainImg})`;
export const grayGroundAvatar = `url(${grayGroundImg})`;
export const carAvatar = `url(${carImg})`;
export const tierAvatar = `url(${tierImg})`;
export const legsAvatar = `url(${legsImg})`;
export const mapAvatar = `url(${mapImg})`;
export const craneType1Avatar = `url(${craneType1Img})`;
export const soilAvatar = `url(${soilImg})`;
export const semiRoadAvatar = `url(${semiRoadImg})`;
export const profileAvatar = `url(${profilePhoto})`;
export const languageIconsAvatar = `url(${languageIconImg})`;


export const pictures = [
  birdImg,
  boatImg,
  buildingType1Img,
  carImg,
  cityImg,
  city2Img,
  city3Img,
  craneType1Img,
  fenceImg,
  graduatedImg,
  grayGroundImg,
  groundWaterImg,
  mapImg,
  mountainImg,
  personImg,
  profilePhoto,
  railwaySignImg,
  semiRoadImg,
  soilImg,
  streetLightImg,
  tierImg,
  trainImg,
  trainGroundImg,
  windowImg,
  legsImg,
];

export enum Directions {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
}

export enum ScrollWindowList {
  PLATFORM = 'platform',
  CLOUD = 'cloud',
  MOUNTAIN_AND_CITY = 'mountainAndCity',
}

export const scrollBy = {
  [ScrollWindowList.PLATFORM]: 15,
  [ScrollWindowList.CLOUD]: 4,
  [ScrollWindowList.MOUNTAIN_AND_CITY]: 10,
};
export enum BLOCKS_NAME {
  GRASS_BLOCK = 'GRASS_BLOCK',
  SEA_BLOCK = 'SEA_BLOCK',
  SOIL_BLOCK = 'SOIL_BLOCK',
  CEMENT_BLOCK = 'CEMENT_BLOCK',
  FINISH_BLOCK = 'FINISH_BLOCK',
}

export enum VEHICLE_TYPE {
  EMPTY = 1,
  FULL = 2,
}

export enum VEHICLE_POSITION {
  LEFT = 1,
  RIGHT = 2,
}

const FINISH_LEFT = 24200;

export const BLOCKS_PROPERTIES = [
  {
    key: 1,
    left: 1000,
    classes: [blockStyle.block_cyan],
    type: BlockType.Normal,
    name: BLOCKS_NAME.GRASS_BLOCK,
  },
  {
    key: 2,
    left: 3100,
    classes: [],
    type: BlockType.Sea,
    name: BLOCKS_NAME.SEA_BLOCK,
  },
  {
    key: 3,
    left: 7000,
    classes: [blockStyle.block_wood],
    type: BlockType.Normal,
    name: BLOCKS_NAME.SOIL_BLOCK,
  },
  {
    key: 4,
    left: 12200,
    classes: [blockStyle.block_cement],
    type: BlockType.Normal,
    name: BLOCKS_NAME.CEMENT_BLOCK,
  },
  {
    key: 5,
    left: 17700,
    classes: [blockStyle.block_cement_type2],
    type: BlockType.Normal,
    name: BLOCKS_NAME.CEMENT_BLOCK,
  },
  {
    key: 6,
    left: FINISH_LEFT + 100,
    classes: [blockStyle.block_cement_type2],
    type: BlockType.Hidden,
    name: BLOCKS_NAME.FINISH_BLOCK,
  },
];

export const ABOVE_PLATFORM_GATES = [
  {
    key: 1,
    title: 'Level 1',
    subTitle: 'About Me',
    left: 1500,
  },
  {
    key: 2,
    title: 'Level 2',
    subTitle: 'Higher Education',
    lightColor: commonStyle.wood20,
    darkColor: commonStyle.wood30,
    left: 5400,
  },
  {
    key: 3,
    title: 'Level 3',
    subTitle: 'Graduation',
    lightColor: commonStyle.gray15,
    darkColor: commonStyle.gray18,
    left: 10100,
  },
  {
    key: 4,
    title: 'Level 4',
    subTitle: 'Professional Skills',
    lightColor: commonStyle.gray15,
    darkColor: commonStyle.gray18,
    left: 12760,
  },
  {
    key: 5,
    title: 'Level 5',
    subTitle: 'Work Profile',
    lightColor: commonStyle.gray15,
    darkColor: commonStyle.gray18,
    left: 15550,
  },
  {
    key: 6,
    title: 'Level 6',
    subTitle: 'Projects',
    lightColor: commonStyle.gray20,
    darkColor: commonStyle.gray30,
    left: 17200,
  },
  {
    key: 7,
    title: 'Finish',
    subTitle: 'Thank You!',
    left: FINISH_LEFT,
  },
];

export const ABOVE_PLATFORM_BOATS = [
  {
    left: 3200,
    forwardRef: React.createRef(),
    isRotateBack: false,
    position: VEHICLE_POSITION.LEFT,
  },
  {
    left: 4859,
    forwardRef: React.createRef(),
    isRotateBack: false,
    position: VEHICLE_POSITION.RIGHT,
  },
];

export const ABOVE_PLATFORM_TRAINS = [
  {
    left: 7700,
    forwardRef: React.createRef(),
    isRotateBack: false,
    position: VEHICLE_POSITION.LEFT,
  },
  {
    left: 9363,
    forwardRef: React.createRef(),
    isRotateBack: false,
    position: VEHICLE_POSITION.RIGHT,
  },
];

export const ABOVE_PLATFORM_CARS = [
  {
    left: 13200,
    forwardRef: React.createRef(),
    isRotateBack: false,
    position: VEHICLE_POSITION.LEFT,
  },
  {
    left: 14900,
    forwardRef: React.createRef(),
    isRotateBack: false,
    position: VEHICLE_POSITION.RIGHT,
  },
];

export const ABOUT_ME_LEFT = 2000;
export const HIGHER_EDUCATION_LEFT = 5870;
export const GRADUATION_LEFT = 10642;

export const EMPLOYEMENTHISTORY_PADDING_LEFT = 300;
export const EMPLOYEMENTHISTORY_LEFT = 15820;
export const EMPLOYEMENTHISTORY_CRANE_LEFT = 10550;

export const PROJECTS_LEFT = 18100;
export const PROJECTS_PADDING_LEFT = 600;

export const SKILLS_LEFT = 13100;


export const FIRECRACKERS_LEFT = FINISH_LEFT;
