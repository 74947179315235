import React from 'react';
import { classnames } from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport, IEmploymentHistory } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './employment-history.module.scss';

interface IEmploymentHistoryComponent extends IDefaultResponsiveSupport {
    employmentHistory?: IEmploymentHistory[]
}

export default class EmploymentHistoryComponent extends React.PureComponent<IEmploymentHistoryComponent> {
    render() {
        const { responsiveMode, employmentHistory } = this.props;
        return (
            <div className={classnames(styles.employmentHistoryContainer, ResponsiveModeService.responsiveModeClass(styles, responsiveMode))}>
                <h1 className={classnames('resumeHeading1')}>Employment History</h1>
                {employmentHistory?.map((history, i, array) => this.getEmpHistory(history, i, array[0]))}
            </div>
        );
    }

    private getEmpHistory(history: IEmploymentHistory, index: number, header: IEmploymentHistory): JSX.Element | undefined {
        const { responsiveMode } = this.props;

        if (responsiveMode === 'mobile' && index !== 0) {
            return (
                <div key={index} className={classnames(styles.employmentHistoryCard, { lightBg: (index % 2 !== 0) })}>
                    <div className={classnames('flex-row')}>
                        <div className={classnames('size-4', 'tableHeader')}><p>{header.organisation}</p></div>
                        <div className={classnames('size-8')}><p>{history.organisation} {history.location}</p></div>
                    </div>
                    <div className={classnames('flex-row')}>
                        <div className={classnames('size-4', 'tableHeader')}><p>{header.designation}</p></div>
                        <div className={classnames('size-8')}><p>{history.designation}</p></div>
                    </div>
                    <div className={classnames('flex-row')}>
                        <div className={classnames('size-4', 'tableHeader')}><p>{header.period}</p></div>
                        <div className={classnames('size-8')}><p>{history.period}</p></div>
                    </div>
                </div>
            );
        } else if (responsiveMode !== 'mobile') {
            return (
                <div key={index} className={classnames('flex-row', { tableHeader: index === 0, lightBg: (index % 2 === 0) })}>
                    <div className={classnames('size-1')}><p>{index === 0 ? '#' : index}</p></div>
                    <div className={classnames('size-3')}><p>{history.organisation} {index !== 0 ? history.location : null}</p></div>
                    <div className={classnames('size-4')}><p>{history.designation}</p></div>
                    <div className={classnames('size-4')}><p>{history.period}</p></div>
                </div>
            );
        } 
    }
}