import React from 'react';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import {
  IAboutMe,
  IDefaultResponsiveSupport,
  IPersonProps,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import GlobeComponent from './globe/globe.component';
import TVComponent from './tv/tv.component';
import styles from './about-me.module.scss';

interface IAboutMeComponent extends IDefaultResponsiveSupport {
  left: number;
  scrollPostion: number;
  personProps: IPersonProps;
  aboutMe: IAboutMe;
}

interface IAboutMeComponentState {
  animateGlobe: boolean;
  animateTv: boolean;
}

export default class AboutMeComponent extends React.Component<
  IAboutMeComponent,
  IAboutMeComponentState
> {
  private eleRef: React.RefObject<any>;

  constructor(props: IAboutMeComponent) {
    super(props);
    this.eleRef = React.createRef();
    this.state = {
      animateGlobe: false,
      animateTv: false,
    };
  }

  shouldComponentUpdate(
    nextProps: IAboutMeComponent,
    nextState: IAboutMeComponentState
  ) {
    const { scrollPostion, responsiveMode } = this.props;
    if (
      scrollPostion !== nextProps.scrollPostion &&
      (!this.state.animateGlobe || !this.state.animateTv)
    ) {
      this.runAnimation(nextProps);
    }

    if (
      responsiveMode !== nextProps.responsiveMode ||
      this.state.animateGlobe !== nextState.animateGlobe ||
      this.state.animateTv !== nextState.animateTv
    ) {
      return true;
    }
    return false;
  }

  private runAnimation({
    personProps: { getBoundingClientRect: getPersonBoundingClientRect },
  }: IAboutMeComponent) {
    const block = this.eleRef?.current?.getBoundingClientRect();

    if (getPersonBoundingClientRect?.right > block.left) {
      this.setState({ animateGlobe: true });
    }

    if (getPersonBoundingClientRect?.right > block.right) {
      this.setState({ animateTv: true });
    }
  }

  render() {
    const { responsiveMode, left, aboutMe } = this.props;
  
    return (
      <div
        ref={this.eleRef}
        style={{
          left,
        }}
        className={classnames(
          styles.aboutMeContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <GlobeComponent
          isAnimated={this.state.animateGlobe}
          responsiveMode={responsiveMode}
        />
        <TVComponent
          aboutMe={aboutMe!}
          isAnimated={this.state.animateTv}
          responsiveMode={responsiveMode}
        />
      </div>
    );
  }
}
