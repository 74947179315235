import React from 'react';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IPersonProps,
} from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './employment-history.module.scss';
import { IEmploymentHistory as IEmpHistory } from '../../../../common/interfaces/common.interface';
import PullyComponent from '../../../../components/pulley/pulley.component';
import { EMPLOYEMENTHISTORY_PADDING_LEFT } from '../../../../common/constant/interactive.resume.constant';
import { ISetSkyProps } from '../../../../context/action';

interface IEmploymentHistory extends IDefaultResponsiveSupport {
  left: number;
  scrollPostion: number;
  personProps: IPersonProps;
  employmentHistorys: IEmpHistory[];
  setSkyProps: ISetSkyProps;
}

interface IEmploymentHistoryState {
  runAnimation: boolean;
}

export default class EmploymentHistory extends React.Component<
  IEmploymentHistory,
  IEmploymentHistoryState
> {
  private eleRef: React.RefObject<any>;

  constructor(props: IEmploymentHistory) {
    super(props);
    this.eleRef = React.createRef();
    this.state = {
      runAnimation: false,
    };
  }

  shouldComponentUpdate(
    nextProps: IEmploymentHistory,
    nextState: IEmploymentHistoryState
  ) {
    const { scrollPostion, responsiveMode } = this.props;
    if (scrollPostion !== nextProps.scrollPostion && !this.state.runAnimation) {
      this.runAnimation(nextProps);
    }

    if (
      responsiveMode !== nextProps.responsiveMode ||
      this.state.runAnimation !== nextState.runAnimation
    ) {
      return true;
    }
    return false;
  }

  private runAnimation({
    personProps: { getBoundingClientRect: getPersonBoundingClientRect },
    setSkyProps,
  }: IEmploymentHistory) {
    const bulding = this.eleRef?.current?.getBoundingClientRect();

    if (getPersonBoundingClientRect?.right > bulding.left) {
      this.setState({ runAnimation: true });
      setSkyProps({ backgroundColor: styles.skyColor4 });
    }
  }

  render() {
    const { responsiveMode, left, employmentHistorys } = this.props;

    return (
      <div
        ref={this.eleRef}
        style={{
          left,
        }}
        className={classnames(
          styles.employmentHistoryContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        {employmentHistorys
          .reverse()
          .map((item, i) =>
            i === employmentHistorys.length - 1
              ? null
              : this.getEmploymentElement(item, i)
          )}
      </div>
    );
  }

  private getEmploymentElement(employmentHistorys: IEmpHistory, index: number) {
    return (
      <div
        className={classnames('flex-col', styles.employmentElementContainer, {
          [styles.moveDown]: this.state.runAnimation,
        })}
        key={index}
        style={{
          left: EMPLOYEMENTHISTORY_PADDING_LEFT * (index + 1),
          animationDelay: `${0.4 * index}s`,
        }}
      >
        <div className={classnames('size-auto', styles.chain)}>
          <p>Work Experience #{index + 1}</p>
        </div>
        <PullyComponent
          style={{
            top: '-15px',
          }}
        />
        <div className={classnames('flex-col', styles.cabinet)}>
          <p className={classnames(styles.organisation)}>
            {employmentHistorys.organisation}
          </p>
          <p className={classnames(styles.designation)}>
            {employmentHistorys.designation}
          </p>
          <p
            className={classnames(styles.period, {
              [styles.active]:
                employmentHistorys?.shortPeriod?.includes('Present'),
            })}
          >
            {employmentHistorys.shortPeriod}
          </p>
        </div>
      </div>
    );
  }
}
