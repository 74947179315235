import React from 'react';
import { classnames } from '../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../common/interfaces/common.interface';
import ResponsiveModeService from '../../common/services/responsive-mode.service';
import styles from './pulley.module.scss';

interface IPullyComponent extends IDefaultResponsiveSupport {
  style?: React.CSSProperties;
}

export default class PullyComponent extends React.PureComponent<IPullyComponent> {
  render() {
    const { responsiveMode, style } = this.props;
    return (
      <div
        style={style}
        className={classnames(
          styles.pulleyContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div className={styles.circle}></div>
      </div>
    );
  }
}
