import React from 'react';
import {
  CancelSVG,
  DarkModeSVG,
  DocumentSVG,
  DownloadSVG,
  FullscreenExitSVG,
  FullscreenSVG,
  HomepageSVG,
  LightModeSVG,
  PersonSVG,
  ScreenRotationSVG,
} from '../../common/constant/resume-data.constant';
import {
  classnames,
  LinkMemo,
} from '../../common/general-utilities/general-utilities';
import {
  IResumeData,
  IScreen,
  NAV_EVENT_TYPE,
  PAGE_ROUTES,
  SCREEN_ORIENTATION,
  SCREEN_ORIENTATION_LABEL,
} from '../../common/interfaces/common.interface';
import styles from './side-nav.module.scss';
import LayoutService from '../../common/services/layout.service';
import { ISetDarkMode, ISetSideNavBarVisibility } from '../../context/action';

interface ISideNavComponent {
  setSideNavBarVisibility: ISetSideNavBarVisibility;
  setDarkMode: ISetDarkMode;
  isDark: boolean;
  screen: IScreen;
  resumeData: Partial<IResumeData>;
}

class SideNavComponent extends React.Component<ISideNavComponent> {
  shouldComponentUpdate(nextProps: ISideNavComponent) {
    const { screen, isDark } = this.props;
    if (
      screen.isFullScreen !== nextProps.screen.isFullScreen ||
      screen.orientation !== nextProps.screen.orientation ||
      isDark !== nextProps.isDark
    ) {
      return true;
    }
    return false;
  }

  private handleClick = (event: NAV_EVENT_TYPE) => {
    const { setSideNavBarVisibility } = this.props;
    setTimeout(() => {
      this.handleNavEventAction(event);
    }, 200);
    setSideNavBarVisibility && setSideNavBarVisibility(false);
  };

  private handleNavEventAction = (event: NAV_EVENT_TYPE) => {
    const { setDarkMode, isDark, screen } = this.props;
    switch (event) {
      case NAV_EVENT_TYPE.DARK_LIGHT_MODE:
        setDarkMode(!isDark);
        break;
      case NAV_EVENT_TYPE.FULL_SCREEN_ON_OFF:
        screen.isFullScreen
          ? LayoutService.closefullscreen()
          : LayoutService.openfullscreen();
        break;
      case NAV_EVENT_TYPE.ROTATE_SCREEN:
        screen.orientation === SCREEN_ORIENTATION.LANDSCAPE
          ? LayoutService.rotateScreen(SCREEN_ORIENTATION.PORTRAIT)
          : LayoutService.rotateScreen(SCREEN_ORIENTATION.LANDSCAPE);
        break;
    }
  };

  render() {
    const { isDark, screen, resumeData } = this.props;
    return (
      <div
        className={classnames(styles.sideNavMenuListContainer, {
          [styles.isDark]: isDark,
        })}
      >
        <ul className={classnames(styles.menuList)}>
          <li className={styles.link}>
            <LinkMemo
              exact
              to={PAGE_ROUTES.HOME}
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <HomepageSVG className={classnames(styles.menuIcon)} />
              <label className={classnames(styles.menuLabel)}>Home</label>
            </LinkMemo>
          </li>
          <li className={styles.link}>
            <LinkMemo
              to={PAGE_ROUTES.PLAIN_RESUME}
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <DocumentSVG className={classnames(styles.menuIcon)} />
              <label className={classnames(styles.menuLabel)}>Portfolio</label>
            </LinkMemo>
          </li>
          <li className={styles.link}>
            <LinkMemo
              to={PAGE_ROUTES.INTERACTIVE_RESUME}
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <PersonSVG className={classnames(styles.menuIcon)} />
              <label className={classnames(styles.menuLabel)}>
                Interactive Resume
              </label>
            </LinkMemo>
          </li>
          <li>
            <a
              onClick={this.handleClick.bind(null, NAV_EVENT_TYPE.CANCEL)}
              className={styles.linkContainer}
              href={resumeData?.resumePath}
              download={resumeData?.aboutMe?.name?.split(' ')?.join('_') + '_Resume'}
            >
              <DownloadSVG className={classnames(styles.menuIcon)} />
              <label className={classnames(styles.menuLabel)}>
                Download CV
              </label>
            </a>
          </li>
          <li className={styles.lineBreak}></li>
          <li>
            <button
              onClick={this.handleClick.bind(
                null,
                NAV_EVENT_TYPE.DARK_LIGHT_MODE
              )}
              className={styles.button}
            >
              {isDark ? (
                <LightModeSVG className={styles.menuIcon} />
              ) : (
                <DarkModeSVG className={styles.menuIcon} />
              )}
              <label className={classnames(styles.menuLabel)}>
                {isDark ? 'Light Mode' : 'Dark Mode'}
              </label>
            </button>
          </li>
          <li>
            <button
              onClick={this.handleClick.bind(
                null,
                NAV_EVENT_TYPE.ROTATE_SCREEN
              )}
              className={styles.button}
            >
              <ScreenRotationSVG className={classnames(styles.menuIcon)} />
              <label className={classnames(styles.menuLabel)}>
                {screen.orientation === SCREEN_ORIENTATION.LANDSCAPE
                  ? SCREEN_ORIENTATION_LABEL[SCREEN_ORIENTATION.PORTRAIT]
                  : SCREEN_ORIENTATION_LABEL[SCREEN_ORIENTATION.LANDSCAPE]}
              </label>
            </button>
          </li>
          <li>
            <button
              onClick={this.handleClick.bind(
                null,
                NAV_EVENT_TYPE.FULL_SCREEN_ON_OFF
              )}
              className={styles.button}
            >
              {screen.isFullScreen ? (
                <FullscreenExitSVG className={classnames(styles.menuIcon)} />
              ) : (
                <FullscreenSVG className={classnames(styles.menuIcon)} />
              )}
              <label className={classnames(styles.menuLabel)}>
                {screen.isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
              </label>
            </button>
          </li>
          <li>
            <button
              onClick={this.handleClick.bind(null, NAV_EVENT_TYPE.CANCEL)}
              className={styles.button}
            >
              <CancelSVG className={classnames(styles.menuIcon)} />
              <label className={classnames(styles.menuLabel)}>Exit</label>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default SideNavComponent;
