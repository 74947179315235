import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider as GlobalProivder } from './context/global.context';
import { HashRouter as Router } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <GlobalProivder>
      <Router>
        <App />
      </Router>
    </GlobalProivder>
  </React.StrictMode>,
  document.getElementById('root')
);
