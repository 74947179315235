import React from 'react';
import { classnames } from '../../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../../common/services/responsive-mode.service';
import styles from './lighthouse.module.scss';

interface ILighthoseComponent extends IDefaultResponsiveSupport {
    left: number;
}
export default class LighthoseComponent extends React.PureComponent<ILighthoseComponent> {
    render() {
        const { responsiveMode, left } = this.props;
        return (
            <div style={{ left }} className={classnames('flex-col', styles.lighthouseContainer, ResponsiveModeService.responsiveModeClass(styles, responsiveMode))}>
                <div className={classnames('flex-col', 'size-4', styles.lightBox)}>
                    <div className={classnames('size-auto', styles.dome)}></div>
                    <div className={classnames('size-3', styles.top)}></div>
                    <div className={classnames('size-6', styles.middle)}>
                        <div className={classnames('flex-row', styles.lightContainer)}>
                            <div className={classnames(styles.lightBall)}>
                                <div className={classnames(styles.lightLeft)}></div>
                                <div className={classnames(styles.lightRight)}></div>
                            </div>
                        </div>
                        <div className={classnames(styles.line)}></div>
                        <ul className={classnames('flex-row', styles.noBullet, styles.balcony)}>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div className={classnames('size-2', styles.bottom)}></div>
                </div>
                <div className={classnames('size-8', styles.pole)}>
                    <ul className={classnames('flex-col', styles.noBullet, styles.redStrips)}>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        );
    }
}