import React from 'react';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IDefaultResponsiveSupport,
  IProjects,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import IntersectionObserverComponent from '../../../components/intersection-observer.component';
import styles from './projects.module.scss';

interface IProjectsComponent extends IDefaultResponsiveSupport {
  projects?: IProjects[];
}

export default class ProjectsComponent extends React.PureComponent<IProjectsComponent> {
  render() {
    const { responsiveMode, projects } = this.props;
    return (
      <div
        className={classnames(
          styles.projectsContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <h1 className={classnames('resumeHeading1')}>Projects</h1>
        <div className={styles.projectCardContainer}>
          {projects?.map((project: IProjects, i) =>
            this.getProjectCard(project, i)
          )}
        </div>
      </div>
    );
  }

  private getProjectCard(project: IProjects, index: number): JSX.Element {
    const { responsiveMode } = this.props;
    return (
      <IntersectionObserverComponent
        display='block'
        key={index}
        className={'fadeInAnimation'}
        animationName={
          responsiveMode === 'mobile' ? 'fadeInFromTop' : 'fadeInFromRight'
        }
        observerOneTime={true}
        threshold={0.1}
      >
        <div key={index} className={styles.card}>
          <h1 className={classnames('resumeHeading2')}>
            <b>{project.projectName} </b>
            <span className={styles.period}>
              {project.period ? `(${project.period})` : null}
            </span>
          </h1>
          <p>
            <b>Overview: </b>
            <span
              dangerouslySetInnerHTML={{ __html: project.projectOverview }}
            ></span>
          </p>
          {project.role ? (
            <p>
              <b>Role: </b>
              {project.role}
            </p>
          ) : null}
          {project.responsibilities && project.responsibilities.length ? (
            <p>
              <b>Responsibilities: </b>
            </p>
          ) : null}
          <ul>
            {project.responsibilities?.map((item, i) => (
              <li key={i}>
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </IntersectionObserverComponent>
    );
  }
}
