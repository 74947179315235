import { ResponsiveMode } from '../interfaces/common.interface';

interface IStyles {
    readonly [key: string]: string
}

export default class ResponsiveModeService {
    public static responsiveModeClass(styles: IStyles, responsiveMode: ResponsiveMode | undefined) {
        switch (responsiveMode) {
            case 'mobile':
                return styles.mobileMode;
            case 'tablet':
                return styles.tabletMode;
            default:
                return styles.desktopMode;
        }
    }
}