import React from 'react';
import {
  grayGroundAvatar,
  groundWaterAvatar,
  semiRoadAvatar,
  soilAvatar,
  trainGroundAvatar,
} from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './platform.module.scss';

interface IPlatFormComponent extends IDefaultResponsiveSupport {
  scrollPostion?: number;
}
export default class PlatformComponent extends React.Component<IPlatFormComponent> {
  private eleRef: React.RefObject<any>;

  constructor(props: IPlatFormComponent) {
    super(props);
    this.eleRef = React.createRef();
  }

  shouldComponentUpdate(nextProps: IPlatFormComponent) {
    const { scrollPostion, responsiveMode } = this.props;
    if (responsiveMode !== nextProps.responsiveMode) {
      return true;
    }
    if (scrollPostion !== nextProps.scrollPostion) {
      this.eleRef.current.scrollTo(nextProps.scrollPostion, 0);
    }
    return false;
  }

  render() {
    const { responsiveMode } = this.props;
    return (
      <div
        ref={this.eleRef}
        className={classnames(
          'fullScreenWindow',
          styles.platformContainer,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode)
        )}
      >
        <div className={classnames('windowReel')}>
          <div
            className={classnames(styles.platform, styles.aboutMePlatForm)}
          ></div>
          <div
            style={{
              backgroundImage: groundWaterAvatar,
            }}
            className={classnames(styles.platform, styles.waterPlatForm)}
          ></div>
          <div
            style={{
              backgroundImage: `${semiRoadAvatar},${soilAvatar}`,
            }}
            className={classnames(styles.platform, styles.higherEducationPlatForm)}
          ></div>
          <div
            style={{
              backgroundImage: trainGroundAvatar,
            }}
            className={classnames(styles.platform, styles.railwayPlatForm)}
          ></div>
          <div
            className={classnames(styles.platform, styles.graduationPlatForm)}
          ></div>
          <div
            className={classnames(styles.platform, styles.plainRoadPlatForm)}
          ></div>
          <div
            style={{
              backgroundImage: grayGroundAvatar,
            }}
            className={classnames(styles.platform, styles.workProfilePlatForm)}
          ></div>
        </div>
      </div>
    );
  }
}
