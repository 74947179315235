import React from 'react';
import { classnames } from '../../../common/general-utilities/general-utilities';
import {
  IContactInfo,
  IDefaultResponsiveSupport,
  IResumeData,
} from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import styles from './about-me.module.scss';

interface IAboutMe extends IDefaultResponsiveSupport {
  aboutMe?: IResumeData['aboutMe'];
}

export default class AboutMeComponent extends React.PureComponent<IAboutMe> {
  render() {
    const { responsiveMode, aboutMe } = this.props;
    return (
      <div
        className={classnames(
          styles.aboutMe,
          ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
          'flex-row',
          'supportMobileFlex'
        )}
      >
        <div className={classnames('size-6', styles.aboutMeHeading)}>
          <h1 className={classnames('resumeHeading1')}>About Me</h1>
          <p
            className={styles.aboutMeParagraph}
            dangerouslySetInnerHTML={{
              __html: aboutMe?.summary?.replace(
                '#YEAR',
                (new Date().getFullYear() - 2014).toString()
              )?.replace("#SKILL", aboutMe?.skill!) as string,
            }}
          ></p>
        </div>
        <div className={classnames('size-6', styles.contactInfo)}>
          {Object.keys(aboutMe?.contactInfo!)?.map((key, i) => {
            return (
              <div key={i} className={classnames('flex-row')}>
                <div className={classnames('size-3')}>
                  <p>{key}</p>
                </div>
                <div className={classnames('size-9')}>
                  <p>{aboutMe?.contactInfo?.[key as keyof IContactInfo]}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
